import { ElementRef, HostListener, Injectable } from '@angular/core';
import LocomotiveScroll from 'locomotive-scroll';
import { ResizeObserver } from '@juggle/resize-observer';

@Injectable({
  providedIn: 'root'
})
export class ScrollManagerService {
  scroll: any;
  savedOffset: any;
  callbacks: {} = {};
  scrollSubs: Function[] = [];

  initialized: boolean = false;
  ignoreResize: boolean = false;

  isMobile() {
    return window.screen.availWidth < 1024;
  }
  initLocomotiveScroll() {
    if (this.isMobile()) {
      this.scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: false,
        multiplier: 1,
        getSpeed: true,
        getDirection: true,
        reloadOnContextChange: true,
        touchMultiplier: 3,
        smoothMobile: 0,
        smartphone: {
          breakpoint: 0,
          smooth: true,
          multiplier: 3,
          touchMultiplier: 5,
          lerp: 1
        },
        tablet: {
          breakpoint: 768,
          smooth: true,
          multiplier: 3,
          lerp: 1
        },
      });
    } else {
      this.scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        multiplier: 1,
        getSpeed: true,
        getDirection: true,
        reloadOnContextChange: true,
        touchMultiplier: 2,
        smoothMobile: 0,
        smartphone: {
          breakpoint: 0,
          smooth: false,
          multiplier: 3,
        },
        tablet: {
          breakpoint: 768,
          smooth: false,
          multiplier: 3
        },
      });
    }


    this.scroll.on('call', (name, type, el) => {
      if (typeof this.callbacks[name] === 'function') {
        return this.callbacks[name](type, el);
      }
    });


    this.scroll.on('scroll', (delta) => {
      this.scrollSubs.forEach((sub) => {
        if (typeof sub === 'function') {
          sub(delta);
        }
      })
    })

    this.initialized = true;
  }

  public updateScroll(scrollUp: boolean = true) {
    if (this.scroll == undefined) return;
    this.saveCurrentOffset()
    const header = document.querySelector('#header');
    header?.classList?.remove('with-title');
    // header.classList.remove('with-border');
    this.scroll && this.scroll.update();
    scrollUp && this.scroll.scrollTo(0, {
      duration: 0,
      offset: -50
    });
    this.setSavedOffset()
  }
  public saveCurrentOffset() {
    this.savedOffset = this.scroll;
  }
  public setSavedOffset() {
    // this.scroll = this.savedOffset;
  }
  public scrollToTarget = (element: Element, offset, duration = 0) => {
    this.scroll.scrollTo(element, {
      duration: duration,
      offset,
      callback: () => {
        this.scroll.update();
      }
    });
  }

  public registerCallback = (name, callback) => {
    this.callbacks[name] = callback;
  }

  public unregisterCallback = (name) => {
    delete this.callbacks[name];
  }

  public subscribeToScroll = (callback: Function) => {
    this.scrollSubs.push(callback);
  }

  constructor() { }
}
