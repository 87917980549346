import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
import { debounce } from "ts-debounce";

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit, OnDestroy {

  textAppear: string = '';
  buttonAppear: string = '';
  brandsAppear: string = '';
  seen: string = '';
  prevTimeStamp: number = 0;

  @ViewChild('intro') introVideo;
  @ViewChild('introContainer') introContainer;

  @ViewChild('preloader') preloader;
  bgs: any;

  constructor(private scrollManager: ScrollManagerService, private router: Router) { }

  ngOnInit(): void {
    this.bgs = document.querySelectorAll('.intro--bg');
  }

  onTimeUpdate = (event) => {
    // if (event.timeStamp > 2000 && this.prevTimeStamp <= 2000) {
    //   this.onAnimationEvent('text');
    // }

    // if (event.timeStamp > 6000 && this.prevTimeStamp <= 6000) {
    //   this.onAnimationEvent('brands');
    // }

    // if (event.timeStamp > 9000 && this.prevTimeStamp <= 9000) {
    //   this.onAnimationEvent('button');
    // }

    // if (event.timeStamp > 12000 && this.prevTimeStamp <= 12000) {
    //   this.onAnimationEvent('');
    // }

    this.prevTimeStamp = event.timeStamp;
  }

  onAnimationEvent = (name: string): void => {
    switch (name) {
      case 'text':
        this.textAppear = 'appear';

      case 'brands':
        this.brandsAppear = 'appear';

      case 'button':
        this.buttonAppear = 'appear';
        break;
      default:

        break;
    }
  }
  currentBrand: string = '';

  onEnter = (brand) => {
    const brandToShow = document.querySelector('#bg-' + brand);
    this.bgs.forEach(element => {
      element.classList.add('hide');
    });
    brandToShow.classList.remove('hide');
    this.currentBrand = brand;
    this.setTheme('dark');
  }

  exitIntro = (type, el) => {
    if (type === 'exit') {
      this.setTheme('dark');
      this.bgs.forEach(element => {
        element.classList.add('hide');
      });
    }
  }

  enterIntro = (type, el) => {
    if (type === "enter") {
      this.onVideoEnded(el.progress);
      if (this.router.url === '/') {
        this.setTheme('dark');
      }
    }
  }

  onVideoEnded = (progress?) => {
    const introContent = document.querySelector('.intro__content');
    introContent.classList.add('appear');
    this.introContainer.nativeElement.classList.add('filled');
  }
  setTheme(theme: string) {
    document.body.dataset.theme = theme;
  }
  onLeave = () => {

  }
  isMobile() {
    return window.screen.availWidth < 1024;
  }
  waitScroll: Function = null;
  onScrolled = (event) => {

    const { scroll } = event;
    const { y } = scroll;


    if (y > 0 && this.waitScroll == null) {
      const intro_container = document.querySelector('.introduction__container');

      if (intro_container == null) return;



      intro_container.classList.add('hide');
      this.onVideoEnded();
      this.setTheme('dark');
      this.waitScroll = debounce(() => {
        this.scrollManager.scroll.start();
      }, 1000);
      this.scrollManager.scroll.stop();

      this.waitScroll();
    }
  }

  videoLoaded = () => {
    const preloader = document.querySelector('.intro__preloader');
    preloader.remove();

    this.introVideo.nativeElement.muted = true;
    this.introVideo.nativeElement.play();

  }

  ngOnDestroy(): void {
    if (this.waitScroll) {
      // @ts-ignore
      this.waitScroll.cancel();
      this.scrollManager.unregisterCallback('video');
      this.onVideoEnded();
    }
  }

  ngAfterViewInit(): void {
    if (this.introVideo) {

      this.introVideo.nativeElement.addEventListener('loadedmetadata', this.videoLoaded);

      this.introVideo.nativeElement.addEventListener('ended', (error) => {
        setTimeout(() => {
          this.onAnimationEvent('text');
        }, 1000)

      });

      this.introVideo.nativeElement.addEventListener('timeupdate', this.onTimeUpdate);
      this.scrollManager.registerCallback('video', this.enterIntro);
      this.scrollManager.subscribeToScroll(this.onScrolled);
    }
  }
}
