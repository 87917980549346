import { Component, Input, OnInit } from '@angular/core';
export interface IPaymentsCard{
  src:string;
  title:string;
  subtitle:string;
}
@Component({
  selector: 'app-payments-card',
  templateUrl: './payments-card.component.html',
  styleUrls: ['./payments-card.component.scss']
})
export class PaymentsCardComponent implements OnInit {
  @Input('src') icon:any;
  @Input('title') title:string;
  @Input('subtitle') subtitle:string;

  constructor() { }

  ngOnInit(): void {
  }

}
