import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-brand-top-banner-2',
  standalone: false,
  templateUrl: './brand-top-banner-2.component.html',
  styleUrl: './brand-top-banner-2.component.scss'
})
export class BrandTopBanner2Component {
  @Input() brand:string = 'tsars'
  @Input() descriptions:string;
  @Input() estabilished:string
}
