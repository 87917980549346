import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

export enum ReviewType {
  "Affiliate" = 'affiliate',
  "User" = 'user'
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  @Input() brand = 'default';
  @Input() type: ReviewType = ReviewType.Affiliate;
  @ViewChild('testimonials') testimonials;
  @Input('withFrom') withFrom: boolean;
  isFirst: string = 'first';
  isLast: string = '';

  currentPosition: number = 0;
  position: string = '';

  reviews: any = {
    "en": [
      { "from": "Pikakasinot", "review": "We are very satisfied.", "stars": 5, "affiliate": "Pikakasinot", "url": "www.pikakasinot.com" },
      { "from": "Pikakasinot", "review": "We are very satisfied.", "stars": 5, "affiliate": "Pikakasinot", "url": "www.pikakasinot.com" },
      { "from": "Pikakasinot", "review": "We are very satisfied.", "stars": 5, "affiliate": "Pikakasinot", "url": "www.pikakasinot.com" },
      { "from": "Pikakasinot", "review": "We are very satisfied.", "stars": 5, "affiliate": "Pikakasinot", "url": "www.pikakasinot.com" },
      { "from": "Pikakasinot", "review": "We are very satisfied.", "stars": 5, "affiliate": "Pikakasinot", "url": "www.pikakasinot.com" }
    ]
  };
  outReviews: any[] = [];

  constructor(private httpClient: HttpClient,
    public scrollManagerService: ScrollManagerService) { }

  ngOnInit(): void {
    this.brand = this.brand?.toLocaleLowerCase();
  }

  moveLeft(e?) {
    if (this.withFrom == undefined) {
      this.withFrom = false;
    }
    if (this.testimonials) {
      const children = this.testimonials.nativeElement.children.length;
      const childWidth = this.testimonials.nativeElement.children[0].clientWidth + 16;

      this.currentPosition = Math.max(0, --this.currentPosition);
      this.position = `-${this.currentPosition * childWidth}`;

      this.isFirst = this.currentPosition <= 0 ? 'first' : '';
      this.isLast = this.currentPosition >= children - 2 ? 'last' : '';
    }
  }

  moveRight(e?) {
    if (this.testimonials) {
      const children = this.testimonials.nativeElement.children.length;
      const childWidth = this.testimonials.nativeElement.children[0].clientWidth + 16;
      this.currentPosition = Math.min(children - 2, ++this.currentPosition);
      this.position = `-${this.currentPosition * childWidth}`;

      this.isFirst = this.currentPosition <= 0 ? 'first' : '';
      this.isLast = this.currentPosition >= children - 2 ? 'last' : '';
    }
  }
  getReviews = (): any[] => {
    return [
      ...this.reviews["en"]
    ]
  }
  onDragedHandler(e) {
  }
  parseUrl(url: string) {
    let link: string = url;
    if (link.includes('https://') == true) {
      link = link.replace('https://', '');
    }
    if (link.includes('www.') == false) {
      link = `www.${link}`;
    }
    return link;
  }
  ngAfterViewInit() {
    // let url = "https://spinwise.s3.eu-central-1.amazonaws.com/reviews.json";
    let url = 'https://spinwisecdn.s3.eu-north-1.amazonaws.com/site/spinwise_reviews.json'

    if (this.type == ReviewType.User) {
      // url = `https://spinwise.s3.eu-central-1.amazonaws.com/${this.brand}_reviews.json`;
      url = `https://spinwisecdn.s3.eu-north-1.amazonaws.com/site/${this.brand}_reviews.json`
    }

    try {
      this.httpClient.get(url, {
        responseType: "json",
        headers: {

          'Access-Control-Allow-Origin': '*',
        }
      }).subscribe((data) => {
        this.reviews = data;
      });
    } catch (e) {

    }
  }
}
