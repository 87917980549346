import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class VideoDialog implements OnInit {
    title = '';
    videoSrc = 'https://www.youtube.com/embed/FlwLgppRF-s?enablejsapi=1';
    @Input('show') show: boolean = false;
    @Input('onClose') onClose: Function;
    @ViewChild('iframe') iframe;

    constructor() {

    }

    ngOnInit(): void {

    }

    closePopup = (e: any) => {
        this.iframe.nativeElement?.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
        );

        this.onClose?.();
    }
}
