import { AfterContentChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
export enum EScrollBannerIcons {
  arrowLeft = 'arrowLeft',
  download = 'download'
}
export enum EScrollBannerBg{
  orange = 'bg-orange',
  black ='bg-black',
}
export interface IScrollBannerComponent {
  title: string;
  titleLink?: string;
  subtitle: string;
  subtitleRoute?: string;
  subtitleHref?: string;
  btnIcon: EScrollBannerIcons;
  background?:EScrollBannerBg;
  positionCount?:number;
}
@Component({
  selector: 'app-scroll-banner',
  templateUrl: './scroll-banner.component.html',
  styleUrls: ['./scroll-banner.component.scss']
})

export class ScrollBannerComponent implements OnInit, AfterContentChecked {

  constructor(private scrollManagerService: ScrollManagerService) { }

  @Input('scrollInputs') scrollInputs: IScrollBannerComponent;
  @Input('dataCall') dataCall: string;
  @Input('dataCallClose') dataCallClose: string;
  @ViewChild("StickyTitle") stickyTitle: ElementRef<HTMLElement>;

  ngOnInit(): void {
    if(this.scrollInputs.background == undefined) this.scrollInputs.background = EScrollBannerBg.black;

  }
  ngAfterContentChecked() {
    this.scrollManagerService.registerCallback(this.dataCall, this.setStickyTitle);
    this.scrollManagerService.registerCallback(this.dataCallClose, this.setStickyTitleClose);
  }
  setStickyTitle = (type, el) => {
    if (el.progress < 1 && type == "exit") {
      this.stickyTitle.nativeElement.classList.remove('show');
      this.stickyTitle.nativeElement.classList.add('hide');
    }
    if (el.progress < 1 && type == "enter") {
      this.stickyTitle.nativeElement.classList.remove('hide');
      this.stickyTitle.nativeElement.classList.add('show');
    }
  }
  setStickyTitleClose = (type, el) => {
    if (el.progress < 1 && type == "enter") {
      this.stickyTitle.nativeElement.classList.remove('show');
      this.stickyTitle.nativeElement.classList.add('hide');
    }
    if (el.progress < 1 && type == "exit") {
      this.stickyTitle.nativeElement.classList.remove('hide');
      this.stickyTitle.nativeElement.classList.add('show');
    }
  }


}
