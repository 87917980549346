import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-brand-top-banner',
  templateUrl: './brand-top-banner.component.html',
  styleUrls: ['./brand-top-banner.component.scss']
})
export class BrandTopBannerComponent implements OnInit {
  @Input() brand:string = 'tsars'
  @Input() descriptions:string;
  @Input() estabilished:string
  @ViewChild('TopBanner') topBannerElement:ElementRef<HTMLElement>;
  @ViewChild('DescriptionText') descriptionTextElement:ElementRef<HTMLElement>;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(){

  }
}
