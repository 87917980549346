<div class="brand-top-banner-container brand-bg-color__{{brand}}">
  <div class="brand__background brand__{{brand}}">
    <div class="mask-shadow">
      <img class="character character__{{brand}}" src="../../../../../assets/images/characters/character-{{brand}}.png" alt="">
    </div>
  </div>
  <div class="brand__description">
    <img src="../../../../../assets/images/logos/{{brand}}.png" alt="">
    <p class="text text-color__{{brand}}">{{descriptions}}</p>
    <br>
    <p class="text text-color__{{brand}}" style="padding-top: 0;">{{estabilished}}</p>
  </div>
</div>
