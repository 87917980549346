<div #StickyTitle id="sticky-title-component" class="sticky-title hide">
  <div class="d-fl jc-sb centered-container ai-fs {{scrollInputs.background}}">
    <div class="d-fl jc-fs ai-c sticky-title--left">
      <!-- with link on title(with dioganal arrow) -->
      <a *ngIf="scrollInputs.titleLink != undefined" href="{{scrollInputs.titleLink}}" target="_blank" style="text-decoration: none;" class="d-fl ai-c">
        <p class="txt-c-2" style="margin-right: '8px';">{{scrollInputs.title}}</p>
        <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-diagonal.svg" [stretch]="true" svgClass="circle"
          [svgStyle]="{'fill':'#FFFFFF'}"></svg-icon> -->
        <svg class="circle" width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9713 17.9706V6.65685H6.6576L6.63992 8.65443L14.5595 8.65443L5.95049 17.2635L7.36471 18.6777L15.9737 10.0686V17.9882L17.9713 17.9706Z" />
        </svg>

      </a>
      <!-- without link on title(without dioganal arrow) -->
      <div *ngIf="scrollInputs.titleLink == undefined && scrollInputs.positionCount == undefined">
        <p class="txt-c-2">{{scrollInputs.title}}</p>
      </div>
      <!-- orange banner with count of oppened positions -->
      <div *ngIf="scrollInputs.titleLink == undefined && scrollInputs.positionCount != undefined" class="d-fl left-container ai-c">
        <div class="circle d-fl jc-c ai-c">
          <p>{{scrollInputs.positionCount}}</p>
        </div>
        <p class="txt-c-2">{{scrollInputs.title}}</p>
      </div>

      <p *ngIf="scrollInputs.subtitleRoute != undefined" routerLink="{{scrollInputs.subtitleRoute}}" class="read-more-mobile">{{scrollInputs.subtitle}}</p>
      <a *ngIf="scrollInputs.subtitleHref != undefined" routerLink="{{scrollInputs.subtitleRoute}}" href="{{scrollInputs.subtitleHref}}" class="read-more-mobile">{{scrollInputs.subtitle}}</a>
    </div>
    <a *ngIf="scrollInputs.subtitleRoute != undefined" class="d-fl jc-c ai-c sticky-title--right" routerLink="{{scrollInputs.subtitleRoute}}">
      <span class="read-more--btn">{{scrollInputs.subtitle}}</span>
      <!-- <svg-icon *ngIf="scrollInputs.btnIcon == 'arrowLeft'" src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true" svgClass="circle" [svgStyle]="{'fill':'#101820','padding':'4px'}"></svg-icon> -->
      <svg *ngIf="scrollInputs.btnIcon == 'arrowLeft'" class="circle" style="padding: 4px;" width="48" height="48" viewBox="0 0 48 48" fill="#101820" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
      </svg>

      <!-- <svg-icon *ngIf="scrollInputs.btnIcon == 'download'" src="../../../../assets/images/icons/svg/download.svg" [stretch]="true" svgClass="circle" [svgStyle]="{'fill':'#101820','padding':'4px'}"></svg-icon> -->
      <svg *ngIf="scrollInputs.btnIcon == 'download'" class="circle" style="padding: 4px;" width="24" height="24" viewBox="0 0 24 24" fill="#101820" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 11L12 16L17 11L15.6 9.55L13 12.15V4H11V12.15L8.4 9.55L7 11Z" />
        <path d="M20 20V18H4V20H20Z" />
      </svg>
    </a>
    <a *ngIf="scrollInputs.subtitleHref != undefined" target="_blank" href="{{scrollInputs.subtitleHref}}" class="d-fl jc-c ai-c sticky-title--right">
      <span class="read-more--btn">{{scrollInputs.subtitle}}</span>
      <!-- <svg-icon *ngIf="scrollInputs.btnIcon == 'arrowLeft'"
        src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true" svgClass="circle"
        [svgStyle]="{'fill':'#101820'}"></svg-icon> -->
      <svg *ngIf="scrollInputs.btnIcon == 'arrowLeft'" class="circle" width="48" height="48" viewBox="0 0 48 48" fill="#101820" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
      </svg>

      <!-- <svg-icon *ngIf="scrollInputs.btnIcon == 'download'" src="../../../../assets/images/icons/svg/download.svg" [stretch]="true" svgClass="circle" [svgStyle]="{'fill':'#101820','padding':'4px'}"></svg-icon> -->
      <svg *ngIf="scrollInputs.btnIcon == 'download'" class="circle" width="24" height="24" viewBox="0 0 24 24" fill="#101820" style="padding: 4px;" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 11L12 16L17 11L15.6 9.55L13 12.15V4H11V12.15L8.4 9.55L7 11Z" />
        <path d="M20 20V18H4V20H20Z" />
      </svg>

    </a>
  </div>
</div>
