import { Component, Input, OnInit } from '@angular/core';
import { EPlanCardSvgName, IPlanCard } from '../plan-card/plan-card.component';

@Component({
  selector: 'app-plan-block',
  templateUrl: './plan-block.component.html',
  styleUrls: ['./plan-block.component.scss']
})
export class PlanBlockComponent implements OnInit {

  constructor() { }
  planBlock: Array<IPlanCard> = [];
  styleBlocks:Array<string> = [`a`,`b`,`c`];
  @Input('withDescription') withDescription:boolean;
  ngOnInit(): void {
    if(this.withDescription == undefined) this.withDescription = true;
    this.planBlock = [
      {
        title: `Revenue Share`,
        subtitle: `Receive a profit share based on your referral performance`,
        liArr: [`NNCO`, `From NGR`, `Zero admin fees`],
        svgName: EPlanCardSvgName.circle1
      },
      {
        title: `Cost Per Acquisition`,
        subtitle: `Get paid per every referral you send our way`,
        liArr: [`No Hold Period`,`Baseline €20`],
        svgName: EPlanCardSvgName.circle2
      },
      {
        title: `Hybrid Plan`,
        subtitle: `Combination of both commission plans`,
        liArr: [`NNCO`, `From NGR`, `No Hold period`],
        svgName: EPlanCardSvgName.circle3,
        isPrimary:true
      },
    ]
  }

}
