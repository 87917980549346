import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private language:Subject<string> = new BehaviorSubject<string>('en');

  get lang$(){
    return this.language.asObservable();
  }

  updateCurrentLanguage(newLanguage: string) {
    this.language.next(newLanguage);
  }
}
