<section class="container-dark d-fl fd-c centered-container gutter-container commissions__container"
 data-scroll-section data-scroll data-scroll-offset="90%, 0%" data-scroll-repeat="true" data-scroll-call="updateHeader"
 >
    <!-- <app-title-and-subtitle
        title="Clear Cut"
        subtitle="commissions"
        description="We guarantee to deliver an even-handed revenue share, regardless of which plan you choose."
        [primaryIcon]="true"
        primaryUrl="/commissions"
    >
    </app-title-and-subtitle> -->
    <div class="clear-cut--points" data-scroll  data-scroll-call="sticky-title" data-scroll-repeat="true" data-scroll data-scroll-offset="60%, 0%" >
        <div class="clear-cut--block d-fl fd-c-r-m jc-fs ai-c block--a fade-in" data-scroll data-scroll-offset="35%">
            <div class="clear-cut--block-item">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1759_5920" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="40" height="40">
                    <circle r="19.0918" transform="matrix(0.70711 0.707104 -0.70711 0.707104 32 31.9998)" fill="#222222"/>
                    </mask>
                    <g mask="url(#mask0_1759_5920)">
                    <rect width="32.6152" height="66.0258" transform="matrix(0.70711 0.707104 -0.70711 0.707104 31.4375 -13.5625)" fill="#222222"/>
                    </g>
                    <mask id="mask1_1759_5920" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="40" height="40">
                    <circle r="19.0918" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 32 32.0002)" fill="#CD7AFF"/>
                    </mask>
                    <g mask="url(#mask1_1759_5920)">
                    <rect width="32.6152" height="66.0258" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 32.5625 77.5625)" fill="#FF7AF2"/>
                    </g>
                </svg>
                <div class="d-fl fd-c jc-c ai-fs block--text">
                    <h3 class="t-c-4 fw-600">Revenue Share</h3>
                    <p class="t-c-5 fw-500">Receive a profit share based on your referral performance</p>
                </div>
            </div>
        </div>
        <div class="clear-cut--block d-fl fd-c-r-m jc-fs ai-c  block--b fade-in" data-scroll data-scroll-offset="35%" >
            <div class="clear-cut--block-item">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="39.9416" cy="39.9392" rx="19.0588" ry="19.0588" transform="rotate(-90 39.9416 39.9392)" fill="#FF7AF2"/>
                    <ellipse cx="24.0584" cy="24.0588" rx="19.0588" ry="19.0588" transform="rotate(90 24.0584 24.0588)" fill="#222222"/>
                    <mask id="mask0_1759_5902" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="39" height="39">
                    <path d="M24.0584 42.1196C14.0848 42.1196 5.99954 34.0344 5.99954 24.0608C5.99954 14.0872 14.0848 6.00195 24.0584 6.00195C34.032 6.00195 42.1172 14.0872 42.1172 24.0608C42.1172 34.0344 34.032 42.1196 24.0584 42.1196Z" fill="#222222" stroke="white" stroke-width="2"/>
                    </mask>
                    <g mask="url(#mask0_1759_5902)">
                    <ellipse cx="39.9416" cy="39.9431" rx="19.0588" ry="19.0588" transform="rotate(-90 39.9416 39.9431)" fill="#AD00FF"/>
                    </g>
                </svg>
                <div class="d-fl fd-c jc-c ai-fs block--text">
                    <h3 class="t-c-4 fw-600">Cost Per Acquisition</h3>
                    <p class="t-c-5 fw-500">Get paid per every referral you send our way</p>
                </div>
            </div>
        </div>
        <div class="clear-cut--block d-fl jc-c ai-c block--c fade-in" data-scroll data-scroll-offset="35%">
            <div class="clear-cut--block-item d-fl fd-c-r-m ai-fs">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="40.215" cy="40.8869" rx="19.1131" ry="18.7853" transform="rotate(-90 40.215 40.8869)" fill="#75FFE6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78551 25.5989C5.92247 30.2987 7.75373 34.9568 11.2793 38.5439C18.6154 46.008 30.5096 46.008 37.8457 38.5439C45.1818 31.0798 45.1818 18.9781 37.8457 11.514C34.2533 7.85888 29.5679 5.99365 24.86 5.91825L24.86 25.5989L5.78551 25.5989Z" fill="#222222"/>
                    <mask id="mask0_1759_5908" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="39" height="40">
                    <circle r="18.9499" transform="matrix(0.700965 0.713196 -0.700965 0.713196 24.5625 25.0299)" fill="#AE03FF"/>
                    </mask>
                    <g mask="url(#mask0_1759_5908)">
                    <rect x="-17.8047" y="-11.8301" width="42.6647" height="37.4297" fill="#75FFE6"/>
                    </g>
                    <mask id="mask1_1759_5908" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="39" height="40">
                    <path d="M24.5585 43.0738C14.7522 43.0738 6.77317 34.9807 6.77317 24.9607C6.77317 14.9408 14.7522 6.84766 24.5585 6.84766C34.3648 6.84766 42.3437 14.9408 42.3437 24.9607C42.3437 34.9807 34.3648 43.0738 24.5585 43.0738Z" fill="#222222" stroke="white" stroke-width="2"/>
                    </mask>
                    <g mask="url(#mask1_1759_5908)">
                    <ellipse cx="40.215" cy="40.8889" rx="19.1131" ry="18.7853" transform="rotate(-90 40.215 40.8889)" fill="#0085FF"/>
                    </g>
                    </svg>

                <div class="d-fl fd-c jc-se ai-se block--text">
                    <h3 class="t-c-4 fw-600">Hybrid Plan</h3>
                    <p class="t-c-5 fw-500">Combination of both commission plans</p>
                </div>
            </div>
        </div>
    </div>
</section>
