<app-animation></app-animation>

<section data-scroll-section class="gutter-container plan-section" data-scroll data-scroll-repeat="true"
  data-scroll-call="updateHeader" data-scroll-offset="95%, 0">
  <app-plan-block [withDescription]="false"></app-plan-block>
</section>
<section data-scroll-section data-scroll data-scroll-repeat="true" data-scroll-call="sticky-title"
  data-scroll-offset="0%, 0%">

  <section data-scroll data-scroll-offset="90%, 0%" data-scroll-repeat="true" class="feature-section">
    <app-features></app-features>
  </section>
</section>

<section data-scroll-section>
  <app-testimonials></app-testimonials>
</section>

<app-get-in-touch title="you get'em" subtitle="we entertain them" offtitle="get in touch">
</app-get-in-touch>
<section data-scroll-section data-scroll data-scroll-repeat="true" data-scroll-offset="0%, 0%"
  data-scroll-call="sticky-title-close">
  <app-contact-us></app-contact-us>
</section>
<section data-scroll-section>
  <app-arrow-banner></app-arrow-banner>
</section>
<app-scroll-banner [scrollInputs]="scrollInputs" dataCall="sticky-title" dataCallClose="sticky-title-close">
</app-scroll-banner>
<div class="intro__preloader d-fl jc-c ai-c fd-c">
  <img src="../../../../assets/images/icons/svg/preloader.svg" alt="">
</div>