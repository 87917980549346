import { Component, Input, OnInit } from "@angular/core";


export interface ILanguage {
  key: string;
  img: string;
  url: string;
}
export interface ILanguages {
  [key: string]: ILanguage;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  languages: ILanguages;
  selectedLanguage: ILanguage;

  @Input('currentLanguage') currentLanguage:any ;

  constructor() { }

  ngOnInit(): void {
    // this.languages = {
    //   en: {
    //     key: 'English',
    //     img: 'en.svg',
    //     url: '/'
    //   },
    //   // at: {
    //   //   key: 'Austrian',
    //   //   img: 'at.svg',
    //   //   url: 'at'
    //   // },
    //   // es: {
    //   //   key: 'Español',
    //   //   img: 'es.svg',
    //   //   url: 'es'
    //   // }
    // };

    // this.selectedLanguage = this.languages[this.currentLanguage];
  }

  // getLanguageArray(): Array<string> {
  //   return Object.keys(this.languages);
  // }

  // filterCurrentLanguage = (language: ILanguage) => {
  //   return language !== this.currentLanguage;
  // }

  // toggleLanguageMenu(e) {
  //   e.stopPropagation();

  //   const el = e.currentTarget;

  //   if (el && el.classList.contains('open'))
  //   {
  //     el.classList.remove('open');
  //   } else {
  //     el.classList.add('open');
  //   }
  // }

  // modifyPath(language: string, path: string): string {
  //   // understand where we are currently:
  //   const slashLastIndex = path.lastIndexOf("/");

  //   // / -> /ru/
  //   if (path === "/") return `/${language}/`;

  //   // homepage with some language e.g. /ru/
  //   if (slashLastIndex === path.length - 1) {
  //     // /ru/ -> /
  //     if (language === "/") return language;

  //     // /es/ <-> /ru/
  //     return path.replace(/(ru|en|at|ca|es)/, language);
  //   }

  //    //  /en/brands/casoo -> /ru/brands/casoo
  //    if (language === "/") return path.replace(/\/(ru|en|at|ca|es)\//, '/en/');

  //    return path.replace(/\/(ru|en|at|ca|es)\//, `/${language}/`);
  // }

  // selectLanguage(language) {
  //   window.location.href = this.modifyPath(language, window.location.pathname);
  // }

}
