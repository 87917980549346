<section class="centered-container section__start" data-scroll-section>
  <div class="title-subtitle-container">
    <app-page-title title="Only the best" subtitle="Partners"
      description="Over the years, we have connected with some of the leading providers in the market to ensure a broad selection of games for our player bases."
      position="center"></app-page-title>
  </div>
</section>
<section data-scroll-section class="centered-container desktop-only-section">
  <div class="payments-container" id="payments" data-scroll>
    <div class="sticky-payments">
      <div class="payments-block payments-block--left" data-scroll data-scroll-sticky data-scroll-target="#payments"
        data-scroll-offset="-20%, 0%">
        <div class="left-block--container">
          <app-payment-system-card *ngFor="let item of paymentsSystemCards;let i = index" size="{{item.size}}" src="{{item.src}}" isMoved="{{item.isMoved}}"></app-payment-system-card>
        </div>

      </div>
      <div class="payments-block payments-block--right" data-scroll data-scroll-speed="2">
        <p class="text--medium title">variety of payments</p>
        <p class="subtitle">We offer an extensive assortment of transaction methods and fast processing times across all
          platforms</p>
        <app-payments-card *ngFor="let item of paymentsCards" src="{{item.src}}" title="{{item.title}}"
          subtitle="{{item.subtitle}}"></app-payments-card>

      </div>
    </div>
  </div>
</section>
<section data-scroll-section class="mobile-only-section">
  <div class="payments-container">
    <div class="non-sticky-payments">
      <div class="payments-block payments-block--left">
        <div class="left-block--container-mobile">
          <app-payment-system-card *ngFor="let item of paymentsSystemCardsMobile;let i = index" src="{{item.src}}" isMoved="{{item.isMoved}}"></app-payment-system-card>

        </div>

      </div>
      <div class="payments-block payments-block--right">
        <p class="text--medium title">variety of payments</p>
        <p class="subtitle">We offer an extensive assortment of transaction methods and fast processing times across all
          platforms</p>
        <div class="payment-card-outer">
          <app-payments-card *ngFor="let item of paymentsCards" src="{{item.src}}" title="{{item.title}}"
          subtitle="{{item.subtitle}}"></app-payments-card>
        </div>
      </div>
    </div>
  </div>
</section>
<section data-scroll-section class="centered-container">
  <div class="providers-count-container">
    <div class="text-container">
      <p class="providers-text title">30 providers</p>
      <p class="games-text title">4 000+ games</p>
      <p class="subtitle-text">An array of top-tier games from dozens of the best providers on the market is the least
        you can expect from each of our brands</p>
    </div>
  </div>
  <div class="games-container">
    <div class="inner-container">
      <div class="line line--1" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-0.5">
        <img src="../../../../assets/images/thumbnails/g-111.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/g-222.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/pr-4.png" alt="">


        <img src="../../../../assets/images/thumbnails/pr-11.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-1.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-1.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-2.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-2.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-3.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-3.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-4.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-6.png" alt="">

      </div>
      <div class="line line--2" data-scroll data-scroll-direction="horizontal" data-scroll-speed="0.5">
        <img src="../../../../assets/images/thumbnails/g-444.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/pr-7.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-666.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/g-333.jpg" alt="">

        <img src="../../../../assets/images/thumbnails/pr-10.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-5.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-6.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-5.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-7.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-8.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-9.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-6.png" alt="">
      </div>
      <div class="line line--3" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-0.5">
        <img src="../../../../assets/images/thumbnails/pr-9.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-777.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/g-888.jpg" alt="">
        <img src="../../../../assets/images/thumbnails/g-555.jpg" alt="">

        <img src="../../../../assets/images/thumbnails/g-10.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-11.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-555.jpg" alt="">

        <img src="../../../../assets/images/thumbnails/g-12.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-13.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-8.png" alt="">
        <img src="../../../../assets/images/thumbnails/g-14.png" alt="">
        <img src="../../../../assets/images/thumbnails/pr-12.png" alt="">

      </div>
    </div>
  </div>
</section>
<app-get-in-touch title="Let's talk" subtitle="get in touch">
</app-get-in-touch>
<section data-scroll-section>
  <app-contact-us></app-contact-us>
  <app-arrow-banner></app-arrow-banner>
</section>
