import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandDescriptionCard } from 'src/app/components/blocks/Brand/brand-description-card/brand-description-card.component';
import { IFeatureBlock } from 'src/app/components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { EPopupType } from 'src/app/components/blocks/Brand/popup-bonus/popup-bonus.component';
import { EScrollBannerIcons, IScrollBannerComponent } from 'src/app/components/shared/scroll-banner/scroll-banner.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

@Component({
  selector: 'app-casoo',
  templateUrl: './casoo.component.html',
  styleUrls: ['./casoo.component.scss']
})
export class CasooComponent implements OnInit {
  brand: string = 'casoo';
  isShowPopupBonus: boolean = false;
  featureBlockArr: Array<IFeatureBlock> = [];
  descriptionCardArr: Array<IBrandDescriptionCard> = [];
  selectedPopup: EPopupType;
  topBrandDescriptions: string = `A casino made by players for players. Indulge in the cosmic realm of Casoo’s games and the intergalactic journey in the conquest to find all the hidden gems of the universe. From spinning to winning we ensure your gaming experience at Casoo feels special. There is no one reason in the universe not to become a part of this breathtaking journey.`;
  estabilished: string = `Established: 2018`;
  @ViewChild('featureBlocks') featureBlocks: ElementRef<Element>
  scrollInputs: IScrollBannerComponent = {
    title: 'Casoo',
    titleLink: 'https://casoo.com/',
    // subtitleHref: '#',
    // subtitle: 'Download kit',
    btnIcon: EScrollBannerIcons.download,
    subtitleHref: 'https://storage.googleapis.com/spinwise-dev-public/brandbooks/casoo.zip',
    subtitle: 'Download kit',
  }
  constructor(
    public scrollManagerService: ScrollManagerService
  ) { }

  ngOnInit(): void {
    this.featureBlockArr = [
      {
        title: `Welcome Pack`,
        text: `The galaxy favours those who wish to explore. This Welcome Pack leads you to the far corners of the universe, where cosmic riches hide.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-2.png`,
        popupType: EPopupType.card1,
      },
      {
        title: `Reload Bonuses`,
        text: `Reach new depths of the galaxy and get rewarded with stellar Reload Bonuses - the perfect fuel for your trips across the universe.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-1.png`,
        popupType: EPopupType.card3,
      },
      {
        title: `Glimmer`,
        text: `Earn Glimmer, Casoo’s intergalactic currency, to spend on spins and other cosmic perks at the Glimmer store.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-3.png`,
        popupType: EPopupType.card2
      }
    ];
    this.descriptionCardArr = [
      {
        titleCard: `Casino`,
        title1: `Operator`,
        subtitle1: `SG International N.V.`,
        title2: `Launched`,
        subtitle2: `2018`,
        title3: `License`,
        subtitle3Name: `Curacao`,
        subtitle3: `The website casoo.com - SG International LP., registered in Scotland,UK with registered address 29d Bradshaw Street, Saltcoats, Scotland, United Kingdom, KA21 5HR, registration No. SL023302 and its subsidiary of SG International N.V. a company registered and established under the laws of Curaçao, with registration number 137028 and registered address at Dr. M.J. Hugenholtzweg 25 Unit 11, Curacao. SG International N.V. is licensed by Antillephone N.V., license no. 8048/JAZ2015-035. There is a service agreement concluded between SG International LP and SG International N.V.`,
        brand: this.brand
      },
      {
        titleCard: `Games`,
        title1: `Games total`,
        subtitle1: `3000+`,
        title3: `Game providers`,
        subtitle3: `1x2 Network, Amatic, Authentic Gaming, BetGames,  Betsoft, Big Time Gaming, Blueprint Gaming, Booming, Edict, EGT, ELK, Evolution, Evoplay, Ezugi, Fantasma, Fugaso, Gamomat, GoldenRace, Habanero, Hacksaw Gaming, High5, iSoftBet, Kiron, Lucky Streak, Mascot Games, Microgaming, MrSlotty, NetEnt, NoLimitCity, PariPlay, PGSoft, Playson, Pragmatic Play, Pragmatic Play Live, Push Gaming, Red Tiger Gaming, ReelPlay, Relax Gaming, SA Gaming, Spinomenal, Spribe, Stakelogic, Thunderkick, Wazdan, WorldMatch, Yggdrasil`,
        brand: this.brand
      },
      {
        titleCard: `Payments`,
        title1: `Method amount`,
        subtitle1: `20+`,
        title2: `Methods`,
        subtitle2: `Visa / Mastercard; CashtoCode; Bank transfer; Rapid transfer; Skrill; Jeton, Trustly; Neosurf; Ecopayz; Neteller; Bitcoin; Bitcoin 2 Cash; ETH; BTC; LTC; EzeeWallet; Mifinity; Paysafecard`,
        title3: `Currencies`,
        subtitle3: `EUR; RUB; USD; NOK; CAD; AUD; NZD`,
        brand: this.brand
      },
      {
        titleCard: `Support`,
        title3: `Contacts`,
        subtitle3: `Live chat, help@casoo.com`,
        brand: this.brand
      },
    ]
  }
  openPopup = (e) => {
    this.isShowPopupBonus = true;
    this.selectedPopup = e;
    this.scrollManagerService.scroll.stop();
  }
  closePopup = (e) => {
    this.scrollManagerService.scrollToTarget(this.featureBlocks.nativeElement, -100, 0);
    this.isShowPopupBonus = false;
    this.scrollManagerService.scroll.start();
  }
}
