import { Component, Input, OnInit } from '@angular/core';
import { IPhotosCardComponent } from './photos-card/photos-card.component';
@Component({
  selector: 'app-team-photos',
  templateUrl: './team-photos.component.html',
  styleUrls: ['./team-photos.component.scss']
})
export class TeamPhotosComponent implements OnInit {

  constructor() { }
  @Input('cardArr') cardArr: Array<IPhotosCardComponent>;
  ngOnInit(): void {
  }

}
