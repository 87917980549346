import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandDescriptionCard } from 'src/app/components/blocks/Brand/brand-description-card/brand-description-card.component';
import { IFeatureBlock } from 'src/app/components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { EPopupType } from 'src/app/components/blocks/Brand/popup-bonus/popup-bonus.component';
import { EScrollBannerIcons, IScrollBannerComponent } from 'src/app/components/shared/scroll-banner/scroll-banner.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
@Component({
  selector: 'app-wisho',
  templateUrl: './wisho.component.html',
  styleUrls: ['./wisho.component.scss']
})
export class WishoComponent implements OnInit {
  @ViewChild('featureBlocks') featureBlocks: ElementRef<Element>

  brand: string = 'wisho';
  isShowPopupBonus: boolean = false;
  featureBlockArr: Array<IFeatureBlock> = [];
  descriptionCardArr: Array<IBrandDescriptionCard> = [];
  selectedPopup: EPopupType;
  topBrandDescriptions: string = `Expect to receive nothing less than exactly what you desire on your fortune-seeking travels at Wisho casino. On your path to wish-fufillment, you will find that Wisho's swift transactions, refined game selection and sleek design can realise your deepest desires in a snap of a finger. It's time to take fate in your own hands and find your fortuners at Wisho.`;
  estabilished: string = `Launched: 2020`;

  scrollInputs: IScrollBannerComponent = {
    title: 'Wisho',
    titleLink: 'https://www.wisho.com/en',
    subtitleHref: 'https://storage.googleapis.com/spinwise-dev-public/brandbooks/wisho.zip',
    subtitle: 'Download kit',
    btnIcon: EScrollBannerIcons.download,
  }
  constructor(
    public scrollManagerService: ScrollManagerService
  ) { }

  ngOnInit(): void {
    this.featureBlockArr = [
      {
        title: `Welcome Pack`,
        text: `The welcome package at Wisho will aid in your wishful pursuits of fortunes. Your dream could be just a few clicks away!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-3.png`,
        popupType: EPopupType.card1,
      },
      {
        title: `Daily Reward`,
        text: `As you begin your pursuit of wishes, you will find spectacular daily rewards. This one is a gift that keeps on giving.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-2.png`,
        popupType: EPopupType.card2,
      },
      {
        title: `Mystery Bonus`,
        text: `Exploring the unknown is what you need to uncover new desires. Every Wednesday, you will receive a magnificent mystery bonus.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-1.png`,
        popupType: EPopupType.card3
      }
    ];
    this.descriptionCardArr = [
      {
        titleCard: `Casino`,
        title1: `Operator`,
        subtitle1: `Hitz Gaming OÜ`,
        title2: `Launched`,
        subtitle2: `2020`,
        title3: `License`,
        subtitle3Name: `Estonia`,
        subtitle3: `Estonian Tax and Customs Board has issued the following gambling licenses to Hitz Gaming OÜ (register code 16217486): HKT000062, HKL000371. Registered address of the company: Pärnu mnt 31-48 Tallinn Harjumaa 10119, Estonia`,
        brand: this.brand
      },
      {
        titleCard: `Games`,
        title1: `Games total`,
        subtitle1: `1800+`,
        title3: `Game providers`,
        subtitle3: `Amatic, Amusnet Interactive, BetSoft, Big Time Gaming, Blueprint Gaming, ELK, Evolution Gaming, Evoplay Entertainment, Fantasma, Gamomat, Greentube, Hacksaw Gaming, Microgaming, NetEnt, Nolimit City, Octoplay, Play'n Go, Playson, Pragmatic Play, Pragmatic Play Live, Push Gaming, Quickspin, Red Tiger Gaming, Relax Gaming, Spinomenal, Thunderkick, Wazdan, Yggdrasil, Yolted`,
        brand: this.brand
      },
      {
        titleCard: `Payments`,
        title1: `Methods`,
        subtitle1: `Trustly, Visa / Mastercard`,
        title3: `Currencies`,
        subtitle3: `EUR`,
        brand: this.brand
      },
      {
        titleCard: `Support`,
        title3: `Contacts`,
        subtitle3: `Live chat, support@wisho.com`,
        brand: this.brand
      },
    ]
  }
  openPopup = (e) => {
    this.isShowPopupBonus = true;
    this.selectedPopup = e;
    this.scrollManagerService.scroll.stop();
  }
  closePopup = (e) => {
    this.scrollManagerService.scrollToTarget(this.featureBlocks.nativeElement, -100, 0);
    this.isShowPopupBonus = false;
    this.scrollManagerService.scroll.start();

  }

}
