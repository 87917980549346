import { Component, OnInit, ViewChild } from '@angular/core';
import { EPhotoSrc, IPhotosCardComponent } from '../../blocks/team-photos/photos-card/photos-card.component';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  constructor() { }
  isFirst: string = 'first';
  isLast: string = '';
  @ViewChild('testimonials') testimonials;
  currentPosition: number = 0;
  position: string = '';
  teamPhotos: Array<IPhotosCardComponent> = [
    { name: 'Alvis Ledskalniņš’', position: 'Founder', photoSrc: EPhotoSrc.Alvis },
    { name: 'Peter Kovisars’', position: 'Chief Executive Officer', photoSrc: EPhotoSrc.Peter },
    { name: 'Andrejs Orlovs’', position: 'Managing Director', photoSrc: EPhotoSrc.Andrejs },
    { name: 'Kristaps Vabolis’', position: 'Affiliate manager', photoSrc: EPhotoSrc.Kristaps },

    { name: 'Alvis Ledskalniņš’', position: 'Founder', photoSrc: EPhotoSrc.Alvis },
    { name: 'Peter Kovisars’', position: 'Chief Executive Officer', photoSrc: EPhotoSrc.Peter },
    { name: 'Andrejs Orlovs’', position: 'Managing Director', photoSrc: EPhotoSrc.Andrejs },
    { name: 'Kristaps Vabolis’', position: 'Affiliate manager', photoSrc: EPhotoSrc.Kristaps },
  ];
  moveLeft(e?) {
    if (this.testimonials) {
      const children = this.testimonials.nativeElement.children.length;
      const childWidth = this.testimonials.nativeElement.children[0].clientWidth + 20;

      this.currentPosition = Math.max(0, --this.currentPosition);
      this.position = `-${this.currentPosition * childWidth}`;

      this.isFirst = this.currentPosition <= 0 ? 'first' : '';
      this.isLast = this.currentPosition >= children - 1 ? 'last' : '';
    }
  }

  moveRight(e?) {
    if (this.testimonials) {
      const children = this.testimonials.nativeElement.children.length;
      const childWidth = this.testimonials.nativeElement.children[0].clientWidth + 20;
      this.currentPosition = Math.min(children - 1, ++this.currentPosition);
      this.position = `-${this.currentPosition * childWidth}`;

      this.isFirst = this.currentPosition <= 0 ? 'first' : '';
      this.isLast = this.currentPosition >= children - 1 ? 'last' : '';
    }
  }
}
