import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-block-title',
  templateUrl: './plan-block-title.component.html',
  styleUrls: ['./plan-block-title.component.scss']
})
export class PlanBlockTitleComponent implements OnInit {
  @Input('withDescription') withDescription:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
