import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { EFeatureModal } from 'src/app/components/pages/brands/winnerz/winnerz.component';

export interface IBrandFeatureModalComponent {
  imgDesktop: string,
  imgMobile: string
  title: string,
  description: string,
  brand: string,
  cards: Array<{ src: string, title: string, description: string }>
}
@Component({
  selector: 'app-brand-feature-modal',
  standalone: false,
  templateUrl: './brand-feature-modal.component.html',
  styleUrl: './brand-feature-modal.component.scss'
})
export class BrandFeatureModalComponent {
  @ViewChild('content') contentElement: ElementRef<HTMLDivElement>;
  @ViewChild('bgElement') bgElement: ElementRef<HTMLDivElement>;

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: IBrandFeatureModalComponent,
  ) {

  }
  getTopImage = () => {
    if (window.innerWidth > 600) return this.data.imgDesktop

    return this.data.imgMobile
  }
  onClickClose(e: PointerEvent, forceClose: boolean) {
    if (forceClose == true) this.dialogRef.close();
    if (e.target == this.bgElement?.nativeElement) {
      this.dialogRef.close();
    }
  }
}
