import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

const ALL_POS_URL = environment.ALL_POS_URL;
const POS_URL = environment.POS_URL;
const TAG_MAP = environment.TAG_MAP;

export interface IPosition {
  name: string;
  slug: string;
  attributes: [
    {
      name: string;
      options: string[]
    }
  ];
  tags?: [{
    id: number;
    name: string;
  }]
}

export interface IPost {
  content: {
    rendered: string;
  };
  title: {
    rendered: string;
  };
  slug: string;
  x_tags: string;
}
@Injectable({
  providedIn: 'root'
})
export class CmsService {

  private openPositions: BehaviorSubject<IPost[]> = new BehaviorSubject<IPost[]>([]);
  private positions: BehaviorSubject<IPosition[]> = new BehaviorSubject<IPosition[]>([]);

  private location: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  get openPositions$() {
    return this.openPositions.asObservable();
  }

  get positions$() {
    return this.positions.asObservable();
  }

  set openPositions$(update) {
    update.subscribe((newPosts) => {
      this.openPositions.next(newPosts);
    });
  }

  set positions$(update) {
    update.subscribe((newPosts) => {
      this.positions.next(newPosts);
    });
  }

  setLocation(newLocation) {
    this.location.next(newLocation);
  }

  constructor(private http: HttpClient, private route: ActivatedRoute) {

    this.location.subscribe((newLocation) => {
      if (!newLocation) return;

      this.getPositions(newLocation);
    });
  }


  getPositions(tag?: string) {
    if (tag) {
      this.positions$ = this.http.get<IPosition[]>(ALL_POS_URL + `?tag=${TAG_MAP[tag.toLowerCase()]}&per_page=100`);
      return;
    }

    this.positions$ = this.http.get<IPosition[]>(ALL_POS_URL + `?per_page=100`);
    return this.positions$;
  }


  getOpenPosition(position: string) {
    this.openPositions$ = this.http.get<IPost[]>(POS_URL + position + `&per_page=100`);
    return this.openPositions$;
  }
}
