<div class="arrow-banner-small__container" [ngClass]="{'hide': hidden}" #main>
  <div class="arrow-banner__part arrow-banner--left">
    <span *ngIf="!!amount" class="d-fl jc-c ai-c amount__indicator">{{amount}}</span>
    <p [innerHTML]="toHTML(bannerTitle)"></p>
  </div>
  <a class="arrow-banner__part arrow-banner--right" [href]="actionUrl">
    <p class="read-more--btn">{{actionText}}</p>
    <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true" svgClass="circle"
            [svgStyle]="{'fill':'#101820'}"></svg-icon> -->
    <svg width="48" height="48" viewBox="0 0 48 48" fill="#101820" class="circle" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
    </svg>

  </a>
</div>
