<div (click)="openDescription($event)" #DescriptionContainer class="description-container">
  <div class="title-container d-fl ai-c">
    <p>{{titleCard}}</p>
    <div #ButtonOpen class="brand-btn d-fl jc-c ai-c">
      <!-- <svg-icon src="../../../../../assets/images/icons/svg/plus-big.svg" [stretch]="true" svgClass="arrow" ></svg-icon> -->
      <svg class="arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26 34H22V26H14V22H22V14H26V22H34V26H26V34Z" />
      </svg>
    </div>
  </div>
  <div *ngIf="isShow" class="content-container">
    <div class="inner-container">
      <div *ngIf="title1" class="description-row solid-border d-fl">
        <div class="description-text">
          <p class="table-title text">{{title1}}</p>
          <p class="table-subtitle text">{{subtitle1}}</p>
        </div>
        <div (click)="copyToClipboard(subtitle1)" class="description-btn d-fl jc-c ai-c">
          <!-- <svg-icon src="../../../../../assets/images/icons/svg/copy.svg" [stretch]="true" svgClass="arrow" ></svg-icon> -->
          <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z" />
            <path d="M4 8H6V18H16V20H4V8Z" />
          </svg>
        </div>
      </div>

      <div *ngIf="title2" class="description-row solid-border d-fl">
        <div class="description-text">
          <p class="table-title text">{{title2}}</p>
          <p class="table-subtitle text">{{subtitle2}}</p>
        </div>

        <div (click)="copyToClipboard(subtitle2)" class="description-btn d-fl jc-c ai-c">
          <!-- <svg-icon src="../../../../../assets/images/icons/svg/copy.svg" [stretch]="true" svgClass="arrow" ></svg-icon> -->
          <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z"/>
            <path d="M4 8H6V18H16V20H4V8Z" />
            </svg>

        </div>
      </div>

      <div *ngIf="title3" class="description-row d-fl">
        <div class="description-text">
          <p class="table-title text">{{title3}}</p>
          <ng-container *ngIf="subtitle3Name">
            <p class="table-subtitle text">{{subtitle3Name}}<br><br>{{subtitle3}}</p>
          </ng-container>
          <ng-container *ngIf="!subtitle3Name">
            <p class="table-subtitle text">{{subtitle3}}</p>
          </ng-container>
        </div>
        <div (click)="copyToClipboard(subtitle3Name+' '+subtitle3)" class="description-btn d-fl jc-c ai-c">
          <!-- <svg-icon src="../../../../../assets/images/icons/svg/copy.svg" [stretch]="true" svgClass="arrow" ></svg-icon> -->
          <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z"/>
            <path d="M4 8H6V18H16V20H4V8Z" />
            </svg>

        </div>
      </div>
    </div>
  </div>
</div>
