import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

import { EScrollBannerIcons, IScrollBannerComponent } from '../../shared/scroll-banner/scroll-banner.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input('currentLanguage') currentLanguage: any;
  scrollInputs: IScrollBannerComponent = {
    title: 'commissions',
    subtitleRoute: '/commissions',
    subtitle: 'read more',
    btnIcon: EScrollBannerIcons.arrowLeft
  }
  ngOnInit(): void {
    this.dataService.lang$.subscribe((update) => {
      this.currentLanguage = update;
    });

  }

  constructor(private dataService: DataService, private scrollManagerService: ScrollManagerService) {
   
  }

}
