<app-popup-bonus brand="{{brand}}" popupType="{{selectedPopup}}" *ngIf="isShowPopupBonus" (closeClickEvent)="closePopup()"></app-popup-bonus>

<section class="centered-container brand-page__container " data-scroll-section>
  <!-- <app-title-and-subtitle title="A galaxy" title2="of treasure" subtitle="{{brand}}" [primaryIcon]="true"
    primaryIconType="download-button" primaryUrl="/download" primaryButtonText="download kit">
  </app-title-and-subtitle> -->
  <app-brand-top-title title="A galaxy of treasure" subtitle="{{brand}}" downloadTitle="{{scrollInputs.subtitle}}" subtitleLink="https://casoo.com" downloadLink="{{scrollInputs.subtitleHref}}"></app-brand-top-title>
</section>

<section data-scroll-section>
  <app-brand-top-banner estabilished="{{estabilished}}" descriptions="{{topBrandDescriptions}}" brand="{{brand}}">
  </app-brand-top-banner>
</section>

<section data-scroll-section class="content-container">
  <!--TODO: Add link to btn -->
  <section data-scroll class="centered-container" style="margin-top: -1px;">
    <app-brand-title title="features" btnText="download pdf" brand="{{brand}}" btnUrl="#"></app-brand-title>
  </section>

  <section #featureBlocks data-scroll class="centered-container" data-scroll-call="sticky-title" data-scroll-repeat="true">
    <app-brand-feature-block *ngFor="let featureBlock of featureBlockArr" (iconClickEvent)="openPopup($event)" brand="{{featureBlock.brand}}" title="{{featureBlock.title}}" text="{{featureBlock.text}}" urlImg="{{featureBlock.urlImg}}" popupType="{{featureBlock.popupType}}">
    </app-brand-feature-block>
  </section>

  <!-- <section data-scroll class="centered-container" style="padding-bottom: 30px;">
      <app-carousel-promotions brand="{{brand}}"></app-carousel-promotions>
    </section> -->

  <!--TODO: Add link to btn -->
  <section data-scroll class="centered-container">
    <app-brand-title title="brand" brand="{{brand}}" btnText="download pdf" btnUrl="#"></app-brand-title>
  </section>


  <section data-scroll class="centered-container brand-descriptions">
    <app-brand-description-card *ngFor="let card of descriptionCardArr" titleCard="{{card.titleCard}}" title1="{{card.title1}}" title2="{{card.title2}}" title3="{{card.title3}}" subtitle1="{{card.subtitle1}}" subtitle2="{{card.subtitle2}}" subtitle3="{{card.subtitle3}}"
      subtitle3Name="{{card.subtitle3Name}}" brand="{{card.brand}}">
    </app-brand-description-card>
  </section>
  <!--TODO: Change color to brand color -->
  <section data-scroll style="min-width: 100%; background: rgba(0,0,0,0.1)">
    <app-testimonials brand="{{brand}}" type="user"></app-testimonials>
  </section>
</section>

<app-get-in-touch title="Let's talk" subtitle="get in touch">
</app-get-in-touch>

<section data-scroll-section>
  <app-contact-us></app-contact-us>
</section>
<section data-scroll-section>
  <app-arrow-banner></app-arrow-banner>
</section>
<app-scroll-banner [scrollInputs]="scrollInputs" dataCall="sticky-title" dataCallClose="sticky-title-close"></app-scroll-banner>