import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoDialog } from 'src/app/components/blocks/dialog/dialog.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  autoScrollImages = [
    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

  ];

  @ViewChild('videoPopup') videoPopup
  showPopup: boolean = false;

  onPlayVideo() {
    document.body.classList.add('no-scroll');
    this.scrollManagerService.updateScroll(true);
    this.scrollManagerService.scroll.stop();
    this.showPopup = true;
  }

  onClose = () => {
    document.body.classList.remove('no-scroll');

    this.showPopup = false;
    this.scrollManagerService.scroll.start();
  }

  constructor(public scrollManagerService: ScrollManagerService) { }

  ngOnInit(): void {
  }

}
