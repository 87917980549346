import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {

  @Input('title') title;
  @Input('subtitle') subtitle;
  @Input('offtitle') offtitle;
  
  constructor() { }

  ngOnInit(): void {
  }

}
