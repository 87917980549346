<div class="centered-container gutter-container d-fl jc-sb ai-c" id="contact-us-section" data-scroll data-scroll-call="sticky-title-close" data-scroll-repeat="true" data-scroll-offset="0%, 0">
  <div class="contact-us__container d-fl fd-c-r-m jc-sb">
    <!-- main -->
    <ng-container *ngIf="contactOf == 'affiliate'">
      <app-contact-us-card dataScrollDelay="0" name="Contact us" job="via email" imgLink="../../../../assets/images/icons/svg/avatar-spinwise.svg" email="hey@spinwise.com"></app-contact-us-card>
      <app-contact-us-card dataScrollDelay="3" name="Tommy A.S." job="Head of Affiliates" imgLink="../../../../assets/images/t.saarela@spinwise.png?cb=1" email="t.adolfsson@spinwise.com"></app-contact-us-card>
      <app-contact-us-card dataScrollDelay="5" name="Kristaps	V." job="Senior Affiliate Manager" imgLink="../../../../assets/images/avatar-chris.png?cb=1" email="chris@spinwise.com"></app-contact-us-card>
      <app-contact-us-card dataScrollDelay="7" name="Adrian S." job="Junior Affiliate manager" imgLink="../../../../assets/images/a.smiltins@spinwise.png?cb=1" email="a.smiltins@spinwise.com"></app-contact-us-card>
    </ng-container>
    <ng-container *ngIf="contactOf == 'hr'">
      <app-contact-us-card [inCircle]="true" dataScrollDelay="0" name="Contact us" job="Lets chat" imgLink="../../../../assets/images/icons/svg/avatar-spinwise.svg" email="hr@spinwise.com" linkedInName="spinwise" linkedInLink="https://www.linkedin.com/company/spinwise-com"></app-contact-us-card>

      <app-contact-us-card dataScrollDelay="3" name="Neila Ondzule" job="Head of Human Resources" imgLink="../../../../assets/images/avatar-neila.png" email="neila@spinwise.com" linkedInName="neila-ondzule" linkedInLink="https://www.linkedin.com/in/neila-ondzule-7a3199a4/"></app-contact-us-card>

      <app-contact-us-card dataScrollDelay="5" name="Kristiāna Paula Mežule" job="Recruiter" imgLink="../../../../assets/images/avatar-kristiana.png" email="k.mezule@spinwise.com" linkedInName="kristiana-paula-mezule"
        linkedInLink="https://www.linkedin.com/in/kristiana-paula-mezule/"></app-contact-us-card>
    </ng-container>

  </div>
</div>