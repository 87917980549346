import { Component, Input, OnInit } from '@angular/core';
export enum EPaymentSystemCardSize{
  default="default",
  small="small"
}
export interface IPaymentSystemCard{
  src:string;
  isMoved:boolean;
  size?:EPaymentSystemCardSize;
}
@Component({
  selector: 'app-payment-system-card',
  templateUrl: './payment-system-card.component.html',
  styleUrls: ['./payment-system-card.component.scss']
})
export class PaymentSystemCardComponent implements OnInit {
  @Input('src') icon;
  @Input('isMoved') isMoved:boolean;
  @Input('size') size:EPaymentSystemCardSize;
  constructor() { }

  ngOnInit(): void {
  }

}
