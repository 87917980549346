// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ALL_POS_URL: 'https://content.spinwise.com/wp-json/api/v1/products',
  POS_URL: 'https://content.spinwise.com/wp-json/wp/v2/posts?slug=',
  TAG_MAP: {
    riga: 26,
    tallinn: 28,
    limassol: 31,
    cyprus: 32,
    malta: 33,
    'all locations': ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
