import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CmsService, IPosition } from 'src/app/services/cms.service';

export interface IOpenPosition {
  title: string;
  location: string;
  schedule: string;
  slug: string;
  url: string;
  tag: string;
}

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {

  @Input('location') location?: string;
  @Input('limit') limit?: number;

  @Input() title?: string = undefined;
  @Input() subtitle?: string = undefined;
  @Input() viewAll?: boolean = true;
  @Input() except?: string = '';

  openPositions: IOpenPosition[];
  hidePageHeaders: boolean = false;

  constructor(private cmsService: CmsService) {

  }

  ngOnInit(): void {
    this.hidePageHeaders = !this.title && !this.subtitle;
    this.cmsService.positions$.subscribe((data) => {
      if (!data) return;
      this.openPositions = this.convertData(data, this.limit, this.location);
      this.filterPositions(this.location);
    });
  }

  filterCurrent = (item) => {
    return item.slug !== this.except;
  }

  filterPositions = (filter: string) => {
    this.openPositions = this.openPositions.filter((item) => {
      if (!filter) return true;
      if (filter == "All locations") return true;

      //@ts-ignore
      return item.tag == filter;
    });
  }
  convertData(data: IPosition[], limit?: number, location?: string): IOpenPosition[] {
    const sliceIndex = limit ? limit : data.length;
    return data.slice(0, sliceIndex).map((position) => {
      return {
        title: position.attributes.find((attr) => attr.name == "title").options[0],
        location: position.attributes.find((attr) => attr.name == "location").options[0],
        schedule: position.attributes.find((attr) => attr.name == "schedule").options[0],
        slug: position.slug,
        tag: position.tags[0].name
      } as IOpenPosition;
    });
  }

}
