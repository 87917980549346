import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
export interface PopupBonusCard {
  imgUrl: string,
  title: string,
  subtitle: string;
}
export enum EPopupType {
  card1 = 'card1',
  card2 = 'card2',
  card3 = 'card3',
  card4 = 'card4',
  card5 = 'card5'
}
@Component({
  selector: `app-popup-bonus`,
  templateUrl: `./popup-bonus.component.html`,
  styleUrls: [`./popup-bonus.component.scss`]
})

export class PopupBonusComponent implements OnInit {
  prevScrollOffset:any = null;
  @Input() brand: string;
  @Input() popupType: any;
  @Output() closeClickEvent = new EventEmitter<any>();

  @ViewChild(`OverlayElement`) overlayElement: ElementRef<HTMLElement>;
  @ViewChild(`CloseBtn`) closeBtn: ElementRef<HTMLElement>
  popupTitle: string;
  popupSubtitle: string;
  cardArr: Array<PopupBonusCard> = [];
  @HostListener('window:popstate', ['$event'])
  onPopState(e) {
    e.stopPropagation();
    this.closeClickEvent.emit(e);

  }
  constructor(public scrollManagerService: ScrollManagerService
  ) { }
  ngOnInit(): void {
    document.body.classList.add('no-scroll');
    this.scrollManagerService.updateScroll(true);
    this.scrollManagerService.scroll.stop();

    switch (this.brand) {
      case `trickz`:
        switch (this.popupType) {
          case EPopupType.card1:
            this.popupTitle = `Welcome Package`;
            this.popupSubtitle = `A good time is guaranteed as soon as you step foot on Trickz. Get a head-start on your journey by claiming your Welcome Package, jam-packed with the best spins around!`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`,
                title: `First deposit bonus`,
                subtitle: `100 Free Spins`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-2.png`,
                title: `Second deposit bonus`,
                subtitle: `200 Free Spins`
              },
            ];
            break;
          case EPopupType.card2:
            this.popupTitle = `Weekly Cashback`;
            this.popupSubtitle = `You can’t teach an old dog new tricks, but who needs them when you have your weekly treats? With Trickz Cashback, you won’t end a single week without a treat in your pocket.`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`,
                title: `Weekly Spins`,
                subtitle: `If you lose from €20 to €500 on a given week - you will receive Free Spins or Super Spins (bet value €1) as a reward the following week, wager free`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-6.png`,
                title: `Weekly Cashback`,
                subtitle: `If your weekly loss amount exceeds €500, a 10% cashback reward for the amount lost is rewarded the following Monday`
              },
            ];
            break;
          case EPopupType.card3:
            this.popupTitle = `Loyalty Programme`;
            this.popupSubtitle = `At Trickz, the most loyal players get extra heavy packages delivered right to them. Keep playing, unbox them all and enjoy exclusive VIP treats for your eyes only.`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`,
                title: `Vip cashback rates`,
                subtitle: `10% is just the tip of the iceberg - loyal members get exclusive cashback rates`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-2.png`,
                title: `Spins on top games`,
                subtitle: `Wager Free Spins on the hottest slots hand-picked by Trickz`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-3.png`,
                title: `Special VIP offers`,
                subtitle: `Exclusive bonuses tailored specifically to your wants and needs`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-5.png`,
                title: `Increased withdrawal limits`,
                subtitle: `Why worry about limits when you’re having fun? Fun has no limits!`
              },

            ];
            break;
          case EPopupType.card4:
            this.popupTitle = `Weekend Recharge`;
            this.popupSubtitle = `Run out of juice? Trickz can fix that! Weekends at Trickz are packed full of fun and excitement. Pay us a visit every Friday to fast-charge your energy levels back to full capacity ahead of the weekend!`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`,
                title: `Free Spins`,
                subtitle: `Grab your Free Spins on the trendiest slots every week without exception!`
              }
            ];
            break;
          case EPopupType.card5:
            this.popupTitle = `Tuesday Treats`;
            this.popupSubtitle = `Tuesday is a great time to enjoy a little treat. Careful! This bonus may contain traces of spins on the games you love. Come back every Tuesday to see what each week has in store. Something truly fun for sure!`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`,
                title: `Free Spins`,
                subtitle: `Every week the hottest slots end up in the free spin spotlight. Put your trust in Trickz to give you the tastiest treats on the best games out there!`
              }
            ];
            break;
        }
        break;
      case `tsars`:
        switch (this.popupType) {
          case EPopupType.card1:
            this.popupTitle = `Welcome Pack`;
            this.popupSubtitle = `Ready to step into your brand new ship and set sail? This Welcome Pack might be just the wind of fortune you need! All hands on deck!`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`, title: `First deposit bonus`, subtitle: `100% bonus up to €300 + 100 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`, title: `Second deposit bonus`, subtitle: `50% bonus up to €300 + 100 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-3.png`, title: `Third deposit bonus`, subtitle: `40% bonus up to €400` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-2.png`, title: `Fourth deposit bonus`, subtitle: `25% bonus up to €1,000` },
            ];
            break;
          case EPopupType.card2:
            this.popupTitle = `Mystery Bonus`;
            this.popupSubtitle = `Limitless lands of Tsars have always attracted travellers with their infinite mystery and the most enigmatic of them all is Wednesday Mystery Bonus. The only thing you can expect here is the unexpected. That's what mystery is all about. Every Wednesday, there's a new treasure chest waiting to be unlocked and a new mystery to be unravelled.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-5.png`, title: `Bonus every Wednesday`, subtitle: `Spice up your week with a special Tsars adventure every Wednesday` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-7.png`, title: `Best games in all the land`, subtitle: `Free spins on top slots of the week only, guaranteed` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-8.png`, title: `Fun and fair`, subtitle: `Spin away without a worry - we promise terms will be fair every time` },
            ];
            break;
          case EPopupType.card3:
            this.popupTitle = `Daily Reward`;
            this.popupSubtitle = `Every day at Tsars comes with a new surprise. Cashback, free spins, or super spins - you never know what treasure today brings. Those who dare to set sails at Tsars are always rewarded.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-8.png`, title: `Cashback from 10%`, subtitle: `This is just the start and a good one at that!` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-9.png`, title: `Spins on the best slots`, subtitle: `Our crew searches day and night for the best spins in all the lands` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-7.png`, title: `Fair bonus terms`, subtitle: `Giving back the Tsars way - fair and square` },
            ];
            break;
          case EPopupType.card4:
            this.popupTitle = `Weekend Reload `;
            this.popupSubtitle = `Tsars rewards those that dare venture beyond the known. On your weekly hunt for new lands and chests full of gold, we will help you ease into the weekend with extra bonus reloads. At the end of the week, you will get at least a match bonus and extra free spins to play with.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`, title: `Log in during weekends`, subtitle: `Get ready to dock your ship to the weekend bay` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-11.png`, title: `Make a deposit`, subtitle: `Get one step closer to uncovering your weekly treasures` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-10.png`, title: `Play more gain more`, subtitle: `Watch your ship fill up with gold as you venture deeper into the sea` },
            ];
            break;
        }
        break;
      case `wisho`:
        switch (this.popupType) {
          case EPopupType.card1:
            this.popupTitle = `Welcome Pack`;
            this.popupSubtitle = `This welcome package will help you materialise your dreams with ease. These 350 free spins are the perfect start of your interdimensional expedition.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`, title: `First deposit bonus`, subtitle: `200 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-3.png`, title: `Second deposit bonus`, subtitle: `150 free spins` },
            ];
            break;
          case EPopupType.card3:
            this.popupTitle = `Daily Reward`;
            this.popupSubtitle = `As you begin your pursuit of wishes, you will find spectacular daily rewards waiting for you. This one is truly a gift that keeps on giving. The surprise Daily Reward can include free spins/super spins or cashback. Your time at Wisho will determine what you get in the end. Keep exploring and you will always be rewarded.`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-7.png`, title: `Wish more, gain more`,
                subtitle: `The more you play, the more Daily Rewards you will gain`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-6.png`, title: `No wagering requirement`,
                subtitle: `That's right - the Daily Rewards you receive are completely wager free`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-5.png`, title: `Completely personalised`,
                subtitle: `The rewards are alwasy suited to your play style `
              },
            ];
            break;
          case EPopupType.card2:
            this.popupTitle = `Mystery Bonus`;
            this.popupSubtitle = `It wouldn't be much of a mystery if you knew what to expect! But one thing is for certain - each and every mystery bonus will be suited to your needs. The bonus will include free spins on the hottest new slots out there. Come by on Wednesdays and unravel the mystery!`
            this.cardArr = [
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-9.png`, title: `A bonus every Wednesday`,
                subtitle: `From here on out, no other day will be as long-awaited than Wednesdays at Wisho`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-7.png`, title: `Nothing but the best games`,
                subtitle: `You will receive free spins on one of the top games of the week`
              },
              {
                imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-8.png`, title: `Fair bonus terms`,
                subtitle: `This is no mystery - the bonus terms will always be fair and square`
              },
            ];
            break;
        }
        break;
      case `casoo`:
        switch (this.popupType) {
          case EPopupType.card1:
            this.popupTitle = `Welcome Pack`;
            this.popupSubtitle = `To start your intergalactic journey just right, pick up the cosmic Welcome Pack and get €2,000 + 200 free spins across your first four deposits.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`, title: `First deposit bonus`, subtitle: `100% bonus up to €300 + 100 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-3.png`, title: `Second deposit bonus`, subtitle: `50% bonus up to €300 + 100 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-2.png`, title: `Third deposit bonus`, subtitle: `40% bonus up to €400` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`, title: `Fourth deposit bonus`, subtitle: `25% bonus up to €1,000` },
            ];
            break;
          case EPopupType.card2:
            this.popupTitle = `Glimmer`;
            this.popupSubtitle = `Glimmer is what keeps your adventures going! As you go along your adventures through the Casoo galaxy, you will stumble across rich deposits of Glimmer. What’s Glimmer, you ask? It’s Casoo’s intergalactic currency you need to keep your ship fueled so that you can seize more cosmic fortunes.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-7.png`, title: `Earn Glimmer`, subtitle: `Play away at casoo and watch your Glimmer balance rise as the reels spin` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-6.png`, title: `Buy cosmic riches`, subtitle: `Head to the Glimmer store to spend your Glimmer on any galactic gems you like` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-5.png`, title: `Double your Glimmer`, subtitle: `Come on the weekends to play the hottest new games and earn double the Glimmer doing it` },
            ];
            break;
          case EPopupType.card3:
            this.popupTitle = `Reload bonuses`;
            this.popupSubtitle = `It’s easy to get to the top as an explorer. All you need to do is keep on playing and level up. Once you reach a level 60 in our progression system, you will receive weekly reload bonuses to launch into exciting weekend adventures. No time to waste – keep exploring and reach new peaks of fortune!`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-8.png`, title: `At level 60`, subtitle: `You earn your first reload bonus and start getting your weekly boosts` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-9.png`, title: `At level 70`, subtitle: `You get an upgrade - better terms and lower wagering requirements` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-1.png`, title: `At level 80`, subtitle: `Your bonus upgrades exponentially - to the stars and beyond!` },
            ];
            break;
        }
        break;
      case `winnerz`:
        switch (this.popupType) {
          case EPopupType.card1:
            this.popupTitle = `Welcome Package`;
            this.popupSubtitle = `The Welcome Package is where it all begins. The offer consists of 350 free spins across the first two deposit bonuses. All the fun starts here!`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-4.png`, title: `First deposit bonus`, subtitle: `200 free spins` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-2.png`, title: `Second deposit bonus`, subtitle: `150 free spins` },
            ];
            break;
          case EPopupType.card2:
            this.popupTitle = `Mystery Bonus`;
            this.popupSubtitle = `Craving mystery in your day-to-day? They say you have to be in the right place at the right time. Winnerz is the right place and Tuesday is the time for Winnerz! Need to work on your having-fun skills? You're in luck! Come by on Tuesdays to enjoy your mystery bonus and spin the day away.`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-6.png`, title: `Bonus every Tuesday`, subtitle: `Come to Winnerz on Tuesdays for your mystery bonus of the week` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-10.png`, title: `Free spins on the best games`, subtitle: `Get to spinning in the the latest and hottest games from top providers` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-5.png`, title: `Reliable bonus terms`, subtitle: `Tuesday bonus is a mystery but its fair terms are no secret` },
            ];
            break;
          case EPopupType.card3:
            this.popupTitle = `Cashback`;
            this.popupSubtitle = `Receiving a reward after each session? That’s the Winnerz way. The dawn of a new day may come with a few surprises. At Winnerz it always comes with a cashback bonus starting from 10% or free spins on your favourite games. The more fun you have, the more cashback you can expect!`
            this.cardArr = [
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-10.png`, title: `No wagering requirement`, subtitle: `Why worry about wagering when you can just have fun?` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-9.png`, title: `Play more - get more`, subtitle: `Your pockets grow heavier the more you play` },
              { imgUrl: `../../../../../assets/images/icons/${this.brand}/promo-8.png`, title: `Personalised to user preferences`, subtitle: `Each cashback reward will be suited to your needs` },
            ];
            break;
        }
        break;
    }
  }
  closePopup(e: any) {
    if (e.target == this.overlayElement.nativeElement || e.target == this.closeBtn.nativeElement) {
      document.body.classList.remove('no-scroll');
      this.closeClickEvent.emit(e);
      this.scrollManagerService.scroll.start();
    }

  }


  ngOnDestroy() {
  }

}

