<div class="title-and-subtitle__container" id="titleSubtitle">
    <h2 class="text--big t-c-7 fw-700"  data-scroll data-scroll-speed="-1" data-scroll-repeat="true">{{title}}</h2>
    <h2 class="text--big t-c-7 fw-700"  data-scroll data-scroll-speed="-.5" data-scroll-repeat="true">{{title2}}</h2>
    <div class="intro-subtitle__wrapper">
        <div class="d-fl jc-sb ai-c " >
            <p class="text--medium t-c-4 fw-700 subtitle">{{subtitle}}</p>
            <app-text-button *ngIf="arrow == true || arrow == undefined"
                [text]="primaryButtonText"
                [url]="primaryUrl"
                type="primary"
                [iconType]="iconType"
            ></app-text-button>
        </div>
        <p class="description t-c-5 fw-500 subtitle fullWidth__{{fullWidth}}">{{description}}</p>
    </div>
</div>
