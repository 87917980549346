<div #FeatuteBlock class="brand__features--item d-fl ai-c" (click)="openModal($event)">
  <div class="brand-btn d-fl jc-c ai-c">
    <!-- <svg-icon src="../../../../../assets/images/icons/svg/plus-big.svg" [stretch]="true" svgClass="arrow" ></svg-icon> -->
    <svg class="arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26 34H22V26H14V22H22V14H26V22H34V26H26V34Z" />
    </svg>

  </div>
  <div class="brand--item__content d-fl fd-c-r-m  jc-c ai-c">
    <img src="{{urlImg}}" />
    <div class="brand--item__description d-fl fd-c jc-fs ai-fs">
      <h2 class="t-c-4">{{title}}</h2>
      <p class="t-c-5">{{text}}</p>
    </div>
  </div>
</div>