import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us-card',
  templateUrl: './contact-us-card.component.html',
  styleUrls: ['./contact-us-card.component.scss']
})
export class ContactUsCardComponent implements OnInit {
  @Input('dataScrollDelay') dataScrollDelay: string;
  @Input('name') name: string;
  @Input('imgLink') imgLink: string;
  @Input('job') job: string;
  @Input('email') email: string;
  // update
  @Input('linkedInName') linkedInName: string;
  @Input('linkedInLink') linkedInLink: string;
  @Input('inCircle') inCircle: boolean = false;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
