import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import {
  EScrollBannerBg,
  EScrollBannerIcons,
  IScrollBannerComponent,
} from '../../shared/scroll-banner/scroll-banner.component';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit, AfterContentChecked {
  scrollInputs: IScrollBannerComponent = {
    title: 'Open positions',
    subtitleRoute: '/careers/hiring',
    subtitle: 'View all',
    btnIcon: EScrollBannerIcons.arrowLeft,
    background: EScrollBannerBg.orange,
    positionCount: 0,
  };
  autoScrollImages = [
    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

    '../../../../assets/images/carousel/1.jpg',
    '../../../../assets/images/carousel/2.jpg',
    '../../../../assets/images/carousel/3.jpg',
    '../../../../assets/images/carousel/4.jpg',
    '../../../../assets/images/carousel/5.jpg',
    '../../../../assets/images/carousel/6.jpg',

  ];
  openPositions: any;
  rigaPositions: number;
  tallinnPositions: number;
  limassolPositions: number;
  maltaPositions: number;

  isLoading: boolean = true;
  constructor(private cmsService: CmsService) {
    this.cmsService.getPositions();
  }

  ngOnInit(): void {
    this.cmsService.positions$.subscribe((data) => {
      if (!data) return;
      this.openPositions = data.length;
      this.rigaPositions = data.filter(
        (pos) => pos.tags[0].name === 'Riga'
      ).length;
      this.tallinnPositions = data.filter(
        (pos) => pos.tags[0].name === 'Tallinn'
      ).length;
      this.limassolPositions = data.filter(
        (pos) => pos.tags[0].name === 'Limassol'
      ).length;
    });
  }
  ngAfterContentChecked() {
    this.scrollInputs.positionCount = this.openPositions;
  }

  ngAfterViewInit(): void {
    const slides = document.querySelectorAll('.carousel-cell');
    slides.forEach((slide) => {
      slide.classList.add('slide')
    })
  }

  getOpenPositionText(amount: number) {
    return `${amount} open ${amount > 1 ? 'positions' : 'position'}`;
  }
}
