import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '../components/pages/about/about.component';
import { BrandsComponent } from '../components/pages/brands/brands.component';
import { CasooComponent } from '../components/pages/brands/casoo/casoo.component';
import { TsarsComponent } from '../components/pages/brands/tsars/tsars.component';
import { WinnerzComponent } from '../components/pages/brands/winnerz/winnerz.component';
import { WishoComponent } from '../components/pages/brands/wisho/wisho.component';
import { CommissionsComponent } from '../components/pages/commissions/commissions.component';
import { HomeComponent } from '../components/pages/home/home.component';
import { PartnersComponent } from '../components/pages/partners/partners.component';
import { HiringComponent } from '../components/pages/hiring/hiring.component';
import { OpenPositionComponent } from '../components/pages/open-position/open-position.component';
import { CareersComponent } from '../components/pages/careers/careers.component';
import { TrickzComponent } from '../components/pages/brands/trickz/trickz.component';
import { EventComponent } from '../components/pages/partners/event/event.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'at', component: HomeComponent
  },
  {
    path: 'es', component: HomeComponent
  },
  {
    path: 'ru', component: HomeComponent
  },
  {
    path: 'brands',
    component: BrandsComponent,
  },
  {
    path: 'brands/tsars',
    component: TsarsComponent
  },
  {
    path: 'brands/casoo',
    component: CasooComponent
  },
  {
    path: 'brands/wisho',
    component: WishoComponent
  },
  {
    path: 'brands/winnerz',
    component: WinnerzComponent
  },
  {
    path:'brands/trickz',
    component:TrickzComponent
  },
  {
    path: 'commissions',
    component: CommissionsComponent
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'careers/hiring',
    component: HiringComponent
  },
  {
    path: 'careers/:location/hiring',
    component: HiringComponent
  },
  {
    path: 'careers/hiring/:position',
    component: OpenPositionComponent
  },
  // {
  //   path: 'payments',
  //   component: PaymentsComponent
  // },
  // {
  //   path: 'plans',
  //   component: PlansComponent
  // },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'partners/event',
    component: EventComponent
  },
  {
    path: 'about-us',
    component: AboutComponent
  },
  { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
