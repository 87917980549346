import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandDescriptionCard } from 'src/app/components/blocks/Brand/brand-description-card/brand-description-card.component';
import { IFeatureBlock } from 'src/app/components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { EPopupType } from 'src/app/components/blocks/Brand/popup-bonus/popup-bonus.component';
import { EScrollBannerIcons, IScrollBannerComponent } from 'src/app/components/shared/scroll-banner/scroll-banner.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
@Component({
  selector: 'app-trickz',
  templateUrl: './trickz.component.html',
  styleUrls: ['./trickz.component.scss']
})
export class TrickzComponent implements OnInit {
  @ViewChild('featureBlocks') featureBlocks: ElementRef<Element>

  brand: string = 'trickz';
  isShowPopupBonus: boolean = false;
  featureBlockArr: Array<IFeatureBlock> = [];
  descriptionCardArr: Array<IBrandDescriptionCard> = [];
  selectedPopup: EPopupType;
  topBrandDescriptions: string = `At Trickz, we do everything in our power to provide a truly fun and safe experience for all our players. We have worked tirelessly along with a range of amazing providers to create  a platform where our players can come to play their favourite games without worrying about anything else.`;
  estabilished: string = `Established: 2022`;
  scrollInputs: IScrollBannerComponent = {
    title: 'Trickz',
    titleLink: 'https://trickz.com/',
    // subtitleHref: 'https://s3.eu-north-1.amazonaws.com/files.spinwise.com/Trickz-brand.pdf',
    // subtitle: 'Download kit',
    btnIcon: EScrollBannerIcons.download,
    subtitleHref: 'https://storage.googleapis.com/spinwise-dev-public/brandbooks/trickz.zip',
    subtitle: 'Download kit'
  }
  constructor(
    public scrollManagerService: ScrollManagerService
  ) { }

  ngOnInit(): void {
    this.featureBlockArr = [
      {
        title: `Welcome Package`,
        text: `Get the warm welcome you deserve to get a head-start on your journey. `,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-1.png`,
        popupType: EPopupType.card1,
      },
      {
        title: `Weekly Cashback`,
        text: `Start fresh every week with a treat of the Trickz Weekly Cashback!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-4.png`,
        popupType: EPopupType.card2,
      },
      {
        title: `Weekend Recharge`,
        text: `Need something refreshing to finish off the week? The Trickz Weekend Recharge is what you need!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-3.png`,
        popupType: EPopupType.card4
      },
      {
        title: `Tuesday Treats`,
        text: `Tuesday is the time for treats. Check back every week to see which sweet spins are on the menu!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-2.png`,
        popupType: EPopupType.card5
      },
      {
        title: `Loyalty Programme`,
        text: `Trickz always rewards the most adventurous explorers who dare to go for their deepest desires.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-5.png`,
        popupType: EPopupType.card3
      },
    ];
    this.descriptionCardArr = [
      {
        titleCard: `Casino`,
        title1: `Operator`,
        subtitle1: `Hitz Gaming OÜ`,
        title2: `Launched`,
        subtitle2: `2022`,
        title3: `License`,
        subtitle3Name: `Estonia`,
        subtitle3: `Estonian Tax and Customs Board has issued the following gambling licenses to Hitz Gaming OÜ (register code 16217486): HKT000062, HKL000371. Registered address of the company: Pärnu mnt 31-48 Tallinn Harjumaa 10119, Estonia`,
        brand: this.brand
      },
      {
        titleCard: `Games`,
        title1: `Games total`,
        subtitle1: `5000+`,
        title3: `Game providers`,
        subtitle3: `Amatic, Amusnet Interactive, BetSoft, Big Time Gaming, Blueprint Gaming, ELK, Evolution Gaming, Evoplay Entertainment, Fantasma, Gamomat, Greentube, Hacksaw Gaming, Microgaming, NetEnt, Nolimit City, Octoplay, Play'n Go, Playson, Pragmatic Play, Pragmatic Play Live, Push Gaming, Quickspin, Red Tiger Gaming, Relax Gaming, Spinomenal, Thunderkick, Wazdan, Yggdrasil, Yolted`,
        brand: this.brand
      },
      {
        titleCard: `Payments`,
        title1: `Methods`,
        subtitle1: `Trustly, Visa / Mastercard`,
        title3: `Currencies`,
        subtitle3: `EUR`,
        brand: this.brand
      },
      {
        titleCard: `Support`,
        title3: `Contacts`,
        subtitle3: `Live chat, support@trickz.com`,
        brand: this.brand
      },
    ]
  }
  openPopup = (e) => {
    this.isShowPopupBonus = true;
    this.selectedPopup = e;
    this.scrollManagerService.scroll.stop();
  }
  closePopup = (e) => {
    this.scrollManagerService.scrollToTarget(this.featureBlocks.nativeElement, -100, 0);
    this.isShowPopupBonus = false;
    this.scrollManagerService.scroll.start();

  }

}
