import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandDescriptionCard } from 'src/app/components/blocks/Brand/brand-description-card/brand-description-card.component';
import { IBrandFeatureBlock2 } from 'src/app/components/blocks/Brand/brand-feature-block-2/brand-feature-block-2.component';
import { IFeatureBlock } from 'src/app/components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { BrandFeatureModalComponent, IBrandFeatureModalComponent } from 'src/app/components/blocks/Brand/brand-feature-modal/brand-feature-modal.component';
import { IBrandTopTitle2 } from 'src/app/components/blocks/Brand/brand-top-title-2/brand-top-title-2.component';
import { EPopupType } from 'src/app/components/blocks/Brand/popup-bonus/popup-bonus.component';
import { EScrollBannerIcons, IScrollBannerComponent } from 'src/app/components/shared/scroll-banner/scroll-banner.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
export enum EFeatureModal {
  WELCOME_PACKAGE,
  WEEKLY_CASHBACK,
  TUESDAY_MYSTERY,
  LOYALTY_REWARDS,
  WEEKEND_RELOAD,
  SPORTSBOOK
}
@Component({
  selector: 'app-winnerz',
  templateUrl: './winnerz.component.html',
  styleUrls: ['./winnerz.component.scss']
})
export class WinnerzComponent implements OnInit {
  @ViewChild('featureBlocks') featureBlocks: ElementRef<Element>

  brand: string = 'Winnerz';
  featureBlockArr: Array<IBrandFeatureBlock2> = [];
  descriptionCardArr: Array<IBrandDescriptionCard> = [];
  selectedPopup: EPopupType;
  topBrandDescriptions: string = `Winnerz’ state-of-the-art games and weekly promotions are designed to bring joy to your day-to-day. And what is life worth if it's not filled with joy? When you choose Winnerz, you choose top-notch customer service, sleek design and, most importantly, you choose fun. Life without it is just an assembly line. Choose fun. Choose Winnerz.`;
  estabilished: string = `Established: 2022`;

  scrollInputs: IScrollBannerComponent = {
    title: 'Winnerz',
    titleLink: 'https://winnerz.com',
    // subtitleHref: '#',
    subtitle: 'Download kit',
    btnIcon: EScrollBannerIcons.download,
    subtitleHref: 'https://storage.googleapis.com/spinwise-dev-public/brandbooks/winnerz.zip'
  }
  topTitle: IBrandTopTitle2 = {
    title: 'Put fun<br>first with',
    subtitle: this.scrollInputs.title,
    subtitleLink: 'https://winnerz.com',
    downloadLink: this.scrollInputs.subtitleHref.toLocaleLowerCase(),
  }
  constructor(
    public scrollManagerService: ScrollManagerService,
    private dialog: Dialog
  ) { }

  ngOnInit(): void {
    this.featureBlockArr = [
      {
        title: `Welcome Package`,
        text: `Ready to get to all the fun? If the answer is yes - you're in the right place. The Winnerz Welcome Package is where it all begins. Deposit, get your 350 free spins, play, and enjoy the ride.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/welcome-feature-icon.png`,
        popupType: EFeatureModal.WELCOME_PACKAGE,
      },
      {
        title: `Weekly Cashback`,
        text: `Everyone receives a resupply of fun with our Cashback Reward. Can it get better than this? At Winnerz it sure can! Not only is the reward weekly, but it's also personalized and wager-free.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/cashback-feature-01.png`,
        popupType: EFeatureModal.WEEKLY_CASHBACK,
      },
      {
        title: `Tuesday Mystery`,
        text: `They say you have to be in the right place at the right time. Winnerz is the right place and Tuesday is the time for Winnerz!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/mystery-feature-01.png`,
        popupType: EFeatureModal.TUESDAY_MYSTERY
      },
      {
        title: `Loyalty Rewards`,
        text: `The most loyal players get access to the Winnerz VIP club. Play, have fun and secure your entry ticket into this exclusive club!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/loyalty-feature-01.png`,
        popupType: EFeatureModal.LOYALTY_REWARDS
      },

      {
        title: `Weekend Reload`,
        text: `The demand for fun reaches new heights every weekend, but with the Weekend Reload, Winnerz always keeps up. Visit Winnerz every Friday to kick-start your weekend with some well-earned fun!`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/reload-feature-01.png`,
        popupType: EFeatureModal.WEEKEND_RELOAD
      },

      {
        title: `Sportsbook`,
        text: `Explore a new dimension of fun! Find your favourite matches and try out our Winnerz Sportsbook with a neat welcome bonus.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/brand-winnerz-rework/winnerz-feature-icons/dportsbook-feature-01.png`,
        popupType: EFeatureModal.SPORTSBOOK
      },
    ];
    this.descriptionCardArr = [
      {
        titleCard: `Casino`,
        title1: `Operator`,
        subtitle1: `Hitz Gaming OÜ`,
        title2: `Launched`,
        subtitle2: `2022`,
        title3: `License`,
        subtitle3Name: `Estonia`,
        subtitle3: `Estonian Tax and Customs Board has issued the following gambling licenses to Hitz Gaming OÜ (register code 16217486): HKT000062, HKL000371. Registered address of the company: Pärnu mnt 31-66, Tallinn, 10119, Estonia`,
        brand: this.brand
      },
      {
        titleCard: `Games`,
        title1: `Games total`,
        subtitle1: `4000+`,
        title3: `Game providers`,
        subtitle3: `Amusnet, Betsoft, Big Time Gaming, Blueprint Gaming, Booming Games, Endorphina, Evoplay, Fantasma, Gaming Corps, Gamomat, G. Games, Greentube, Hacksaw Gaming, Microgaming, NetEnt, Nolimit City, Octoplay, Play'n Go, Playson, Pragmatic Play, Pragmatic Play Live, Push Gaming, Quicksping, Red Tiger Gaming, Relax Gaming, Slotmill Games, Spinomenal, Thunderkick, Wazdan, Yolted.`,
        brand: this.brand
      },
      {
        titleCard: `Payments`,
        title1: `Methods`,
        subtitle1: `Trustly - Instant Banking Solution `,
        title3: `Currencies`,
        subtitle3: `EUR`,
        brand: this.brand
      },
      {
        titleCard: `Support`,
        title3: `Contacts`,
        subtitle3: `Live chat, support@winnerz.com, +372 54568296`,
        brand: this.brand
      },
    ]
  }
  openPopup = (e: EFeatureModal) => {
    let data: IBrandFeatureModalComponent;
    switch (parseInt(e.toString())) {
      case EFeatureModal.WELCOME_PACKAGE:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-welcome-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-welcome-01.jpg',
          brand: 'winnerz',
          title: 'Welcome Package',
          description: "Ready to get to all the fun? If the answer is yes - you're in the right place. The Winnerz Welcome Package is where it all begins. Deposit, get your 350 free spins, play, and enjoy the ride.",
          cards: [
            { title: '1st Deposit', description: '200 Free Spins', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/bonus-pack-welcome.png' },
            { title: '2nd Deposit', description: '150 Free Spins', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/bonus-pack-monthly.png' },
            { title: '3rd Deposit', description: 'Weekly Cashback', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/cashback.png' }
          ]
        }
        break;
      case EFeatureModal.WEEKLY_CASHBACK:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-cashback-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-cashback-01.jpg',
          brand: 'winnerz',
          title: 'Weekly Cashback',
          description: "Everyone receives a resupply of fun with our Cashback Reward. Can it get better than this? At Winnerz it sure can! Not only is the reward weekly, but it's also personalized and wager-free.",
          cards: [
            { title: 'Weekly Spins', description: "If you lose from €20 to €200 on a given week - you will receive Free Spins as a reward for the following week. The best part? They're wager-free.", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/fs.png' },
            { title: 'Weekly Cashback', description: "If you lose over €200, you'll get a 10% cashback reward for the amount you lost. All cash, no wagering requirements. ", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/cashback.png' },
          ]
        }
        break;
      case EFeatureModal.TUESDAY_MYSTERY:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-mystery-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-mystery-01.jpg',
          brand: 'winnerz',
          title: 'Tuesday Mystery',
          description: "They say you have to be in the right place at the right time. Winnerz is the right place and Tuesday is the time for Winnerz!",
          cards: [
            { title: 'Free Spins', description: "Get a tailored and mysterious free spins bonus every week, after your 2nd deposit.", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/fs.png' },
          ]
        }
        break;
      case EFeatureModal.LOYALTY_REWARDS:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-loyalty-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-loyalty-01.jpg',
          brand: 'winnerz',
          title: 'Loyalty Rewards',
          description: "The most loyal players get access to the Winnerz VIP club. Play, have fun and secure your entry ticket into this exclusive club!",
          cards: [
            { title: 'Increased Cashback', description: '10% is just the start. VIP members get exclusive cashback rates!', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/cashback.png' },
            { title: 'Top Spins', description: "Winnerz knows what keeps your world spinning. Enjoy wager-free spins on the hottest games!", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/fs.png' },
            { title: 'VIP Offers', description: 'Get ready for offers like no other! Enjoy unique rewards tailored specifically to your liking.', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/offers.png' },
            { title: 'Increased withdrawal limits', description: 'You can’t put a lid on fun, so you don’t need to worry about withdrawal limits as a VIP member.', src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/withdraw.png' },
          ]
        }
        break;
      case EFeatureModal.WEEKEND_RELOAD:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-reload-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-reload-01.jpg',
          brand: 'winnerz',
          title: 'Weekend Reload',
          description: "The demand for fun reaches new heights every weekend, but with the Weekend Reload, Winnerz always keeps up. Visit Winnerz every Friday to kick-start your weekend with some well-earned fun!",
          cards: [
            { title: 'Free Spins', description: "Grab your Free Spins on the trendiest slots every week without exception!", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/fs.png' },
          ]
        }
        break;
      case EFeatureModal.SPORTSBOOK:
        data = {
          imgMobile: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-sportsbook-mob-01.jpg',
          imgDesktop: '../../../../../assets/images/brand-winnerz-rework/winnerz-promo-popups/promo-winnerz-sportsbook-01.jpg',
          brand: 'winnerz',
          title: 'Sportsbook',
          description: "Explore a new dimension of fun! Find your favourite matches and try out our Winnerz Sportsbook with a neat welcome bonus.",
          cards: [
            { title: 'Risk Free Bet', description: "Get risk free bets up to €25.", src: 'assets/images/brand-winnerz-rework/winnerz-feature-modal-icons/risk-free-bet.png' },
          ],
        }
        break;

    }

    this.scrollManagerService.scroll.stop();
    this.dialog.open(BrandFeatureModalComponent, { data: data }).closed.subscribe(res => {
      this.closePopup(res)
    })
  }
  closePopup = (e) => {
    this.scrollManagerService.scroll.start();

  }

}
