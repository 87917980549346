<div class="plan-block-title-container">
  <app-plan-block-title [withDescription]="withDescription"></app-plan-block-title>
</div>
<div class="clear-cut--points">
  <div *ngFor="let card of planBlock;let i = index;" class="plan-card-container">
    <app-plan-card *ngIf="withDescription == true" title="{{card.title}}" subtitle="{{card.subtitle}}"
      [liArr]="card.liArr" svgName="{{card.svgName}}" isPrimary="{{card.isPrimary}}" styleBlocks="{{styleBlocks[i]}}">
    </app-plan-card>
    <app-plan-card *ngIf="withDescription == false" title="{{card.title}}" subtitle="{{card.subtitle}}"
      svgName="{{card.svgName}}" isPrimary="{{card.isPrimary}}" styleBlocks="{{styleBlocks[i]}}"></app-plan-card>
  </div>
</div>
