<div class="brand-banner__container d-fl fd-c">
  <div #BgContainer class="img-container d-fl jc-c ai-c">
    <img class="logo" src="{{logo}}" alt="">
    <img class="character" src="{{characterUrl}}" alt="">
    <div class="img-gradient"></div>
  </div>
  <div class="brand-banner__content d-fl jc-sb">
    <!-- data-scroll data-scroll-direction="horizontal"  data-scroll-speed="-1.5" -->
    <div class="d-fl fd-c text-container">
      <div class="d-fl ai-c jc-fs">
        <h2>{{brand_name}}</h2>
        <a href="{{webHref}}" target="_blank" class="arrow-external arrow-external--small">
          <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9713 17.9706V6.65685H6.6576L6.63992 8.65443L14.5595 8.65443L5.95049 17.2635L7.36471 18.6777L15.9737 10.0686V17.9882L17.9713 17.9706Z" />
          </svg>
        </a>

      </div>
      <span>{{brand_slogan}}</span>
    </div>
    <!-- data-scroll data-scroll-direction="horizontal"  data-scroll-speed="1.5" -->
    <a (click)="scrollUp()" routerLink="/brands/{{this.brand}}" class="arrow-button arrow-button--primary d-fl jc-c ai-c">
      <svg class="arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
        </svg>
    </a>
  </div>
</div>
