<div class="overlay-video d-fl jc-c ai-c" [ngClass]="{'show': show}">
    <iframe #iframe style="border: none;margin: 0 auto;" src="https://www.youtube.com/embed/rW1twEqe8Vo?enablejsapi=1"
        width='100%' height="100%" loading="lazy" frameborder="0" webkitallowfullscreen mozallowfullscreen
        allowfullscreen fxFlex="100%"></iframe>

    <div #closeBtn class="close-btn d-fl jc-c ai-c overlay-controls" (click)="closePopup($event)">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"
            style="pointer-events: none;">
            <path style="transform: translateY(9.5px);pointer-events: none;"
                d="M2.6 3.02277L2.6 2.96942L2.6 3.02277ZM1 2.49609C1 3.5178 1.95034 3.96942 2.6 3.96942L22.4 3.96942C23.0497 3.96942 24 3.5178 24 2.49609C24 1.47439 23.0497 1.02276 22.4 1.02276L2.6 1.02277C1.95034 1.02277 1 1.47439 1 2.49609Z"
                fill="#B1B1B1" stroke="#B1B1B1" stroke-width="2" />
            <path style="transform: translateX(10px);pointer-events: none;"
                d="M2.5 24C3.5217 24 3.97333 23.0497 3.97333 22.4L3.97333 2.6C3.97333 1.95034 3.5217 1 2.5 1C1.4783 1 1.02667 1.95034 1.02667 2.6L1.02667 22.4C1.02667 23.0497 1.4783 24 2.5 24Z"
                fill="#B1B1B1" stroke="#B1B1B1" stroke-width="2" />
        </svg>
    </div>
</div>