import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-arrow-banner-small',
  templateUrl: './arrow-banner-small.component.html',
  styleUrls: ['./arrow-banner-small.component.scss']
})
export class ArrowBannerSmallComponent implements OnInit {

  @Input('actionText') actionText;
  @Input('actionUrl') actionUrl;
  @Input('amount') amount;
  @Input('bannerTitle') bannerTitle;
  @Input('hidden') hidden: boolean = false;

  @ViewChild('main') main;
  constructor() { }

  ngOnInit(): void {
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }

  public hide() {
    this.main.nativeElement.classList.add('hide');
    this.main.nativeElement.classList.remove('show');
  }

  public show() {
    this.main.nativeElement.classList.remove('hide');
    this.main.nativeElement.classList.add('show');
  }

}
