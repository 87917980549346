import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CarouselModule } from 'ngx-owl-carousel-o';
import { CallbackPipe } from './modules/callback.pipe';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import * as Hammer from 'hammerjs';
import { ArrowBannerComponent } from './components/blocks/arrow-banner/arrow-banner.component';
import { ClearCutCommissionsComponent } from './components/blocks/clear-cut-commissions/clear-cut-commissions.component';
import { ContactUsComponent } from './components/blocks/contact-us/contact-us.component';
import { GetInTouchComponent } from './components/blocks/get-in-touch/get-in-touch.component';
import { TestimonialsComponent } from './components/blocks/testimonials/testimonials.component';
import { FeaturesComponent } from './components/blocks/features/features.component';
import { BrandsComponent } from './components/pages/brands/brands.component';
import { TitleAndSubtitleComponent } from './components/blocks/title-and-subtitle/title-and-subtitle.component';
import { CommissionsComponent } from './components/pages/commissions/commissions.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { TsarsComponent } from './components/pages/brands/tsars/tsars.component';
import { WinnerzComponent } from './components/pages/brands/winnerz/winnerz.component';
import { CasooComponent } from './components/pages/brands/casoo/casoo.component';
import { TextButtonComponent } from './components/elements/text-button/text-button.component';
import { PromotionCardComponent } from './components/blocks/promotion-card/promotion-card.component';
import { CarouselPromotionsComponent } from './components/blocks/carousel-promotions/carousel-promotions.component';
import { PopupBonusComponent } from './components/blocks/Brand/popup-bonus/popup-bonus.component';
import { BrandBannerComponent } from './components/blocks/brand-banner/brand-banner.component';
import { BrandFeatureBlockComponent } from './components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { BrandDescriptionCardComponent } from './components/blocks/Brand/brand-description-card/brand-description-card.component';
import { BrandTopBannerComponent } from './components/blocks/Brand/brand-top-banner/brand-top-banner.component';
import { BrandTitleComponent } from './components/blocks/Brand/brand-title/brand-title.component';
import { WishoComponent } from './components/pages/brands/wisho/wisho.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AffiliateBlocksComponent } from './components/blocks/Commisions/affiliate-blocks/affiliate-blocks.component';
import { AffiliateCardComponent } from './components/blocks/Commisions/affiliate-card/affiliate-card.component';
import { PlanBlockComponent } from './components/blocks/Commisions/plan-block/plan-block.component';
import { PlanCardComponent } from './components/blocks/Commisions/plan-card/plan-card.component';
import { AnimationComponent } from './components/elements/animation/animation.component';
import { ContactUsCardComponent } from './components/blocks/contact-us/contact-us-card/contact-us-card.component';
import { OpenPositionComponent } from './components/pages/open-position/open-position.component';
import { HiringComponent } from './components/pages/hiring/hiring.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NoMatchComponent } from './components/blocks/no-match/no-match.component';
import { PlanBlockTitleComponent } from './components/blocks/Commisions/plan-block/plan-block-title/plan-block-title.component';
import { PageTitleComponent } from './components/blocks/page-title/page-title.component';
import { BrandTopTitleComponent } from './components/blocks/Brand/brand-top-title/brand-top-title.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { PositionsComponent } from './components/blocks/positions/positions.component';
import { ArrowBannerSmallComponent } from './components/elements/arrow-banner-small/arrow-banner-small.component'
import { ScrollBannerComponent } from './components/shared/scroll-banner/scroll-banner.component';
import { TeamPhotosComponent } from './components/blocks/team-photos/team-photos.component';
import { PhotosCardComponent } from './components/blocks/team-photos/photos-card/photos-card.component';
import { PaymentsCardComponent } from './components/pages/partners/payments-card/payments-card.component';
import { PaymentSystemCardComponent } from './components/pages/partners/payment-system-card/payment-system-card.component';
import { AutoCarouselComponent } from './components/pages/careers/auto-carousel/auto-carousel.component';
import { DropdownMenuComponent } from './components/pages/hiring/dropdown-menu/dropdown-menu.component';
import { TrickzComponent } from './components/pages/brands/trickz/trickz.component';
import { EventComponent } from './components/pages/partners/event/event.component';
import { VideoDialog } from './components/blocks/dialog/dialog.component';
import { BrandDescriptionCard2Component } from './components/blocks/Brand/brand-description-card-2/brand-description-card-2.component';
import { BrandFeatureBlock2Component } from './components/blocks/Brand/brand-feature-block-2/brand-feature-block-2.component';
import { BrandTopTitle2Component } from './components/blocks/Brand/brand-top-title-2/brand-top-title-2.component';
import { BrandTopBanner2Component } from './components/blocks/Brand/brand-top-banner-2/brand-top-banner-2.component';
import { BrandFeatureModalComponent } from './components/blocks/Brand/brand-feature-modal/brand-feature-modal.component';
import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
// making hammer config (3)
export class MainHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    CallbackPipe,
    HomeComponent,
    AboutComponent,
    ArrowBannerComponent,
    ClearCutCommissionsComponent,
    ContactUsComponent,
    GetInTouchComponent,
    TestimonialsComponent,
    FeaturesComponent,
    BrandsComponent,
    TitleAndSubtitleComponent,
    BrandBannerComponent,
    CommissionsComponent,
    PartnersComponent,
    TsarsComponent,
    WinnerzComponent,
    CasooComponent,
    TextButtonComponent,
    BrandFeatureBlockComponent,
    PromotionCardComponent,
    BrandDescriptionCardComponent,
    CarouselPromotionsComponent,
    PopupBonusComponent,
    BrandTopBannerComponent,
    BrandTitleComponent,
    WishoComponent,
    AffiliateBlocksComponent,
    AffiliateCardComponent,
    PlanBlockComponent,
    PlanCardComponent,
    AnimationComponent,
    ContactUsCardComponent,
    OpenPositionComponent,
    HiringComponent,
    ContactUsCardComponent,
    NoMatchComponent,
    PlanBlockTitleComponent,
    PageTitleComponent,
    BrandTopTitleComponent,
    CareersComponent,
    PositionsComponent,
    ArrowBannerSmallComponent,
    ScrollBannerComponent,
    TeamPhotosComponent,
    PhotosCardComponent,
    PaymentsCardComponent,
    PaymentSystemCardComponent,
    AutoCarouselComponent,
    DropdownMenuComponent,
    TrickzComponent,
    EventComponent,
    VideoDialog,
    BrandDescriptionCard2Component,
    BrandFeatureBlock2Component,
    BrandTopTitle2Component,
    BrandTopBanner2Component,
    BrandFeatureModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // CarouselModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    HammerModule,
    DialogModule
    // MatDialogModule,
    // AngularSvgIconModule.forRoot()

  ],
  exports: [MatDialogModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HAMMER_GESTURE_CONFIG, useClass: MainHammerConfig },{provide: DEFAULT_DIALOG_CONFIG, useValue: {hasBackdrop: true}}],

  bootstrap: [AppComponent]
})
export class AppModule { }
