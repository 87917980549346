<section class="container-dark d-fl fd-c gutter-container section__start commissions__container" data-scroll-section
  data-scroll data-scroll-offset="90%, 0%" data-scroll-repeat="true" data-scroll-call="updateHeader">
  <section data-scroll data-scroll-repeat="true" data-scroll-offset="60%, 0%">
    <app-plan-block [withDescription]="true"></app-plan-block>
  </section>

  <div class="affiliate-section">
    <div class="affiliate-section--row d-fl">
      <div class="affiliate-section--row__header d-fl fd-c ai-c">
        <h2 class="t-c-4 fw-700">affiliate or streamer</h2>
        <p class="t-c-4 fw-500">
          If you manage and operate an online casino website or a channel on any streaming platform, there are plenty of partnership options with Spinwise for you too!
        </p>
      </div>
    </div>
    <section data-scroll>
      <app-affiliate-blocks></app-affiliate-blocks>
    </section>
  </div>


</section>
<app-get-in-touch title="Let's talk" subtitle="get in touch">
</app-get-in-touch>
<section data-scroll-section>
  <app-contact-us></app-contact-us>
</section>
<section data-scroll-section>
  <app-arrow-banner></app-arrow-banner>

</section>
