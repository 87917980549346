import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

export interface IBrandDescriptionCard{
  titleCard:string,
  title1?:string,
  subtitle1?:string,
  title2?:string,
  subtitle2?:string,
  title3?:string,
  subtitle3?:string,
  subtitle3Name?:string,
  brand:string,
}
@Component({
  selector: 'app-brand-description-card',
  templateUrl: './brand-description-card.component.html',
  styleUrls: ['./brand-description-card.component.scss']
})
export class BrandDescriptionCardComponent implements OnInit {
  isShow:boolean = false;
  @Input() titleCard:string;
  @Input() title1:string;
  @Input() title2:string;
  @Input() title3:string;
  @Input() subtitle1:string;
  @Input() subtitle2:string;
  @Input() subtitle3:string;
  @Input() subtitle3Name:string;
  @Input() brand:string = 'casoo';
  @ViewChild('DescriptionContainer') descriptionContainer:ElementRef<HTMLElement>;

  constructor(public scrollManagerService: ScrollManagerService) { }
  @ViewChild('ButtonOpen') buttonOpen:ElementRef<HTMLElement>;
  ngOnInit(): void {
    this.scrollManagerService.updateScroll()
    this.scrollManagerService.ignoreResize = true;
  }
  ngAfterViewInit(){
    this.descriptionContainer.nativeElement.classList.add(`brand__${this.brand}`);
  }
  ngOnDestroy() {
    this.scrollManagerService.ignoreResize = false;
  }
  openDescription(event: PointerEvent) {
    let targetEl:HTMLElement = event.target as HTMLElement;
    if(targetEl.classList.contains('description-btn')) return;

    if(this.isShow){
      this.buttonOpen.nativeElement.classList.remove('rotateCrossClass')
      this.buttonOpen.nativeElement.classList.add('rotateCrossClassBack')
    }else{
      this.buttonOpen.nativeElement.classList.remove('rotateCrossClassBack')
      this.buttonOpen.nativeElement.classList.add('rotateCrossClass')
    }
    this.isShow = !this.isShow;
  }
  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
