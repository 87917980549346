import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandDescriptionCard } from 'src/app/components/blocks/Brand/brand-description-card/brand-description-card.component';
import { IFeatureBlock } from 'src/app/components/blocks/Brand/brand-feature-block/brand-feature-block.component';
import { EPopupType } from 'src/app/components/blocks/Brand/popup-bonus/popup-bonus.component';
import { EScrollBannerIcons, IScrollBannerComponent } from 'src/app/components/shared/scroll-banner/scroll-banner.component';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
@Component({
  selector: 'app-tsars',
  templateUrl: './tsars.component.html',
  styleUrls: ['./tsars.component.scss']
})
export class TsarsComponent implements OnInit {
  @ViewChild('featureBlocks') featureBlocks: ElementRef<Element>

  brand: string = 'tsars';
  isShowPopupBonus: boolean = false;
  featureBlockArr: Array<IFeatureBlock> = [];
  descriptionCardArr: Array<IBrandDescriptionCard> = [];
  selectedPopup: EPopupType;
  topBrandDescriptions: string = `At Tsars, the limit goes way past the skies. This is a casino where you get to pave your own path to riches. Tsars’ amazing promotions, lightning-fast withdrawals and 24/7 support are set to ensure smooth sailing for every player. Board your ship, set sails and you will come across tremendous rewards throughout your journey.`;
  estabilished: string = `Established: 2020`;
  scrollInputs: IScrollBannerComponent = {
    title: 'Tsars',
    titleLink: 'https://tsars.com/en',
    // subtitleHref: '#',
    // subtitle: 'Download kit',
    // btnIcon: EScrollBannerIcons.download
    subtitleHref: 'https://storage.googleapis.com/spinwise-dev-public/brandbooks/tsars.zip',
    subtitle: 'Download kit',
    btnIcon: EScrollBannerIcons.download,
  }
  constructor(
    public scrollManagerService: ScrollManagerService
  ) { }

  ngOnInit(): void {
    this.featureBlockArr = [
      {
        title: `Welcome Pack`,
        text: `Explorers new to the lands get extra help to set their sails. Tsars’ Welcome Pack is the wind of fortune you need.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-1.png`,
        popupType: EPopupType.card1,
      },
      {
        title: `Daily Reward`,
        text: `Every day at Tsars comes with a new surprise. Cashback, free spins, or super spins - you never know what treasure today brings.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-4.png`,
        popupType: EPopupType.card2,
      },
      {
        title: `Mystery Bonus`,
        text: `Every Wednesday, there's a new treasure chest waiting to be unlocked and a new mystery to be unravelled.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-3.png`,
        popupType: EPopupType.card3
      },
      {
        title: `Reload Bonuses`,
        text: `Weekends come with the perfect conditions for a takeoff. Enjoy your Weekend Reload treasures and conquer the skies.`,
        brand: `${this.brand}`,
        urlImg: `../../../../../assets/images/icons/${this.brand}/feature-2.png`,
        popupType: EPopupType.card4
      },
    ];
    this.descriptionCardArr = [
      {
        titleCard: `Casino`,
        title1: `Operator`,
        subtitle1: `RR Investments N.V.`,
        title2: `Launched`,
        subtitle2: `2019`,
        title3: `License`,
        subtitle3Name: `Curacao`,
        subtitle3: `Website www.tsars.com is a brand name owned and operated by RR Investments N.V., a company registered and established under the laws of Curacao, which has an agreement with Antillephone N.V for its gaming license. RR Investments N.V. registration number is 137866 and its registered address is at Perseusweg 27A, Willemstad, Curacao. The payment processing is managed by RR Invest LP, with a registered address at: Office 1 145 Kilmarnock Road, Glasgow, Scotland, G41 3JA, United Kingdom companies fully owned by RR Investments N.V. license 8048/jaz2015-046.`,
        brand: this.brand
      },
      {
        titleCard: `Games`,
        title1: `Games total`,
        subtitle1: `3000+`,
        title3: `Game providers`,
        subtitle3: `1x2 Network, Amatic, Authentic Gaming, Betsoft, Big Time Gaming, Blueprint Gaming, Booming, Edict, EGT, ELK, Evolution, Evoplay, Ezugi, Fantasma, Fugaso, Gamomat, Habanero, Hacksaw Gaming, High5, iSoftBet, Lucky Streak, Microgaming, MrSlotty, NetEnt, NoLimitCity, PariPlay, PGSoft, Playson, Pragmatic Play, Pragmatic Play Live, Push Gaming, Red Tiger Gaming, ReelPlay, Relax Gaming, Spinomenal, Spribe, Stakelogic, Thunderkick, Wazdan, WorldMatch, Yggdrasil, Yolted`,
        brand: this.brand
      },
      {
        titleCard: `Payments`,
        title1: `Method amount`,
        subtitle1: `20+`,
        title2: `Methods`,
        subtitle2: `Bank Transfer; BTC, LTC, ETH, USDT, Cash 2 code, Visa / Mastercard, Ecopayz, Interac, Jeton, Neosurf. Neteller, Skrill, Paysafecard, Rapid transfer, Trustly, Instant Banking`,
        title3: `Currencies`,
        subtitle3: `USD; EUR; CZK; PLN; NOK; KZT; AUD; NZD; CAD`,
        brand: this.brand
      },
      {
        titleCard: `Support`,
        title3: `Contacts`,
        subtitle3: `Live chat, support@tsars.com`,
        brand: this.brand
      },
    ]
  }
  openPopup = (e) => {
    this.isShowPopupBonus = true;
    this.selectedPopup = e;
    this.scrollManagerService.scroll.stop();
  }
  closePopup = (e) => {
    this.scrollManagerService.scrollToTarget(this.featureBlocks.nativeElement, -100, 0);
    this.isShowPopupBonus = false;
    this.scrollManagerService.scroll.start();

  }

}
