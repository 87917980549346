<div class="contact-us__item d-fl fd-c jc-c ai-c" data-scroll> <!-- item.... data-scroll-delay="{{dataScrollDelay}}" -->
  <div class="contact-us__item--top d-fl"><!-- item-row -->
    <ng-container *ngIf="inCircle == false">
      <img src="{{imgLink}}" class="contact-us__item--left d-fl jc-c" />
    </ng-container>
    <ng-container *ngIf="inCircle == true">
      <img src="{{imgLink}}" class="contact-us__item--left d-fl jc-c" style="background: #FFF;border-radius:50%" />
    </ng-container>

    <div class="d-fl fd-c jc-se ai-fs contact-us__item--right">
      <p class="fw-800 t-c-4 fs-19">{{name}}</p>
      <span class="fw-500 t-c-5 fs-16">{{job}}</span>
    </div>
  </div>
  <div *ngIf="linkedInName" class="contact-us__item--link d-fl">
    <span class="contact-us__item--left d-fl jc-c">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M5.66667 4H17.3333C18.5302 4 19.5 4.97014 19.5 6.16667V17.8333C19.5 19.0299 18.5302 20 17.3333 20H5.66667C4.4704 20 3.5 19.0296 3.5 17.8333V6.16667C3.5 4.9704 4.4704 4 5.66667 4ZM17.3333 2H5.66667C3.36583 2 1.5 3.86583 1.5 6.16667V17.8333C1.5 20.1342 3.36583 22 5.66667 22H17.3333C19.635 22 21.5 20.1342 21.5 17.8333V6.16667C21.5 3.86583 19.635 2 17.3333 2ZM6.1875 9.597V17.847H8.4375V9.597H6.1875ZM6 7.323C6 8.0535 6.588 8.646 7.3125 8.646C8.03775 8.646 8.625 8.0535 8.625 7.323C8.625 6.5925 8.037 6 7.3125 6C6.588 6 6 6.5925 6 7.323ZM15.1875 17.847H17.4375V12.7778C17.4375 8.838 13.2345 8.98125 12.1875 10.9207V9.597H9.9375V17.847H12.1875V13.644C12.1875 11.3092 15.1875 11.118 15.1875 13.644V17.847Z"
          fill="#CACACA" />
      </svg>
    </span>
    <a href="{{linkedInLink}}" target="_blank" class="contact-us__item--right t-c-5 fw-600">{{linkedInName}}</a>
  </div>
  <div class="contact-us__item--link d-fl">
    <span class="contact-us__item--left d-fl jc-c">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20H15V18H10C5.66 18 2 14.34 2 10C2 5.66 5.66 2 10 2C14.34 2 18 5.66 18 10V11.43C18 12.22 17.29 13 16.5 13C15.71 13 15 12.22 15 11.43V10C15 7.24 12.76 5 10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C11.38 15 12.64 14.44 13.54 13.53C14.19 14.42 15.31 15 16.5 15C18.47 15 20 13.4 20 11.43V10C20 4.48 15.52 0 10 0ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13Z"
          fill="#D4D4D4" />
      </svg>
    </span>
    <a href="mailto:{{email}}" class="contact-us__item--right t-c-6 fw-600 link">{{email}}</a>
  </div>
</div>