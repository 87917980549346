import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input('title') title;
  @Input('subtitle') subtitle;
  @Input('description') description;
  @Input('position') position = 'left'; // left, center, right
  constructor() { }

  ngOnInit(): void {
  }

}
