import { Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener, ElementRef } from '@angular/core';

export interface IDropdownMenuCities {
  name: string;
  longName: string;
}
@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  clickout = (event) => {
    if (event.target == this.subMenuBtn.nativeElement || event.target == this.subMenuBtnIcon.nativeElement) {
      this.openDropdown()
    }
    else {
      this.closeDropDown();
    }
  }
  @ViewChild('subMenuBtn') subMenuBtn: ElementRef<HTMLElement>;
  @ViewChild('subMenuBtnIcon') subMenuBtnIcon: ElementRef<HTMLElement>;
  @Input('countPositions') countPositions: string;
  @Input('selectedCity') selectedCity: string;
  @Output() onSelectItemEvent: EventEmitter<IDropdownMenuCities> = new EventEmitter<IDropdownMenuCities>();

  showDropdown: boolean = false;
  dropdownItems: Array<IDropdownMenuCities> = [
    { name: 'Riga', longName: 'Riga, Latvia' },
    { name: 'Tallinn', longName: 'Tallinn, Estonia' },
    { name: 'Limassol', longName: 'Limassol, Cyprus' },
    { name: 'Malta', longName: 'Malta' },
    { name: 'All locations', longName: 'any' }
  ]
  constructor() { }
  openDropdown = () => {
    this.showDropdown = true;
  }
  closeDropDown = () => {
    this.showDropdown = false;
  }
  selectItem = (item: IDropdownMenuCities) => {
    this.closeDropDown();
    this.onSelectItemEvent.emit(item);
  }

  ngOnInit(): void {
    switch (this.selectedCity) {
      case 'Riga, Latvia':
        this.selectedCity = 'Riga';
        break;
      case 'Tallinn, Estonia':
        this.selectedCity = 'Tallinn';
        break;
      case 'Limassol, Cyprus':
        this.selectedCity = 'Limassol';
        break;
      case 'Malta':
        this.selectedCity = 'Malta';
        break;
      case 'any':
        this.selectedCity = 'All locations';
    }
  }

}
