<div class="brand-top-title-2">
  <div class="right-container">
    <p class="title" [innerHtml]="title"></p>
    <a href="{{subtitleLink}}" target="_blank" class="link-container">
      <p class="subtitle">{{subtitle}}</p>
      <div class="arrowBtn-container">
        <svg class="arrowBtn" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.364595 9.18177L7.41607 2.51164L2.44465 2.51164C1.77144 2.51164 1.22769 1.99729 1.22769 1.36049C1.22769 0.723681 1.77144 0.209338 2.44465
        0.209338L10.3678 0.209338C11.0411 0.209338 11.5848 0.723682 11.5848 1.36049L11.5848 8.8552C11.5848 9.49201 11.0411 10.0064 10.3678 10.0064C9.69464 10.0064
        9.15089 9.49201 9.15089 8.85521L9.14226 4.14447L2.09078 10.8146C1.61608 11.2636 0.839297 11.2636 0.364596 10.8146C-0.110106 10.3656 -0.110106 9.6308 0.364595 9.18177Z" />
        </svg>
      </div>
    </a>
  </div>
  <a href="{{downloadLink}}" target="_blank" class="left-container">
    <p class="download-title">{{downloadTitle}}</p>
    <div class="downloadBtn-container">
      <svg class="donwloadBtn" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.568359 15C0.568359 14.4477 1.01607 14 1.56836 14H15.5684C16.1206 14 16.5684 14.4477 16.5684 15C16.5684 15.5523 16.1206 16 15.5684 16H1.56836C1.01607 16 0.568359 15.5523 0.568359 15Z" />
        <path
          d="M7.83705 11.5591C7.93311 11.6551 8.04716 11.7313 8.17267 11.7833C8.29818 11.8353 8.43271 11.8621 8.56856 11.8621C8.70442 11.8621 8.83894 11.8353 8.96445 11.7833C9.08997 11.7313 9.20401 11.6551 9.30007 11.5591L13.2013 7.6578C13.3953 7.4638 13.5043 7.20068 13.5043 6.92632C13.5043 6.65195 13.3953 6.38883 13.2013 6.19483C13.0073 6.00083 12.7442 5.89184 12.4698 5.89184C12.1955 5.89184 11.9323 6.00083 11.7383 6.19483L9.60304 8.33013V1.03448C9.60304 0.760121 9.49405 0.496996 9.30005 0.302993C9.10605 0.10899 8.84292 0 8.56856 0C8.2942 0 8.03107 0.10899 7.83707 0.302993C7.64307 0.496996 7.53408 0.760121 7.53408 1.03448V8.33013L5.39878 6.19483C5.20477 6.00083 4.94165 5.89184 4.66729 5.89184C4.39293 5.89184 4.12981 6.00083 3.9358 6.19483C3.7418 6.38883 3.63281 6.65195 3.63281 6.92632C3.63281 7.20068 3.7418 7.4638 3.9358 7.6578L7.83705 11.5591Z" />
      </svg>
    </div>

  </a>
</div>