<section class="container-dark d-fl fd-c centered-container gutter-container commissions__container"
  data-scroll-section>
  <div class="title-subtitle-container">
    <app-page-title title="Joy is currency" subtitle="Our brands" position="center"></app-page-title>
  </div>
  <app-brand-banner brand_banner="../../../assets/images/brands/trickz_banner.png" brand_name="Trickz"
    brand_slogan="truly fun">
  </app-brand-banner>
  <app-brand-banner brand_banner="../../../assets/images/brands/winnerz_banner.png" brand_name="Winnerz"
    brand_slogan="where fun comes first">
  </app-brand-banner>
  <app-brand-banner brand_banner="../../../assets/images/brands/wisho_banner.png" brand_name="Wisho"
    brand_slogan="where magic is born" brand_url_character="" brand_url_logo="">
  </app-brand-banner>
  <app-brand-banner brand_banner="../../../assets/images/brands/tsars_banner.png" brand_name="Tsars"
    brand_slogan="beyond the skies">
  </app-brand-banner>
  <app-brand-banner brand_banner="../../../assets/images/brands/casoo_banner.png" brand_name="Casoo"
    brand_slogan="a galaxy of treasure">
  </app-brand-banner>

</section>

<app-get-in-touch title="Let's talk" subtitle="get in touch">
</app-get-in-touch>

<section data-scroll-section>
  <app-contact-us></app-contact-us>

  <app-arrow-banner></app-arrow-banner>
</section>
