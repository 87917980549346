import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EPopupType } from '../popup-bonus/popup-bonus.component';
import { EFeatureModal } from 'src/app/components/pages/brands/winnerz/winnerz.component';
export interface IBrandFeatureBlock2{
  title: string,
  text: string,
  urlImg: string,
  brand: string,
  popupType: EFeatureModal
}
@Component({
  selector: 'app-brand-feature-block-2',
  standalone: false,
  templateUrl: './brand-feature-block-2.component.html',
  styleUrl: './brand-feature-block-2.component.scss'
})
export class BrandFeatureBlock2Component {
  @ViewChild('FeatuteBlock') featureBlock: ElementRef<HTMLElement>;
  @Input() title: string;
  @Input() text: string;
  @Input() urlImg: string;
  @Input() brand: string = 'tsars'
  @Input() popupType: EFeatureModal;
  @Output() iconClickEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    // this.featureBlock.nativeElement.classList.add(`brand__${this.brand}`);
  }
  openModal = (e: any) => {

    this.iconClickEvent.emit(this.popupType);
  }
}
