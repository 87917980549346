<div class="container-wide container-rounded banner__container">
  <div class="get-on-board centered-container  d-fl jc-sb ai-c">
    <div class="text-container">
      <h2 class="get-on-board--title ta-c">Become a partner</h2>
      <p class="get-on-board--subtitle">4k+ affiliates already joined</p>
    </div>
    <a href="https://go.spinwise.partners/signup" target="_blank" class="circle d-fl jc-c ai-c">
      <svg class="arrow" width="48" height="48" viewBox="0 0 48 48" fill="rgb(255,255,255)" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
      </svg>
    </a>
  </div>
</div>