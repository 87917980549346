import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IPromotionCard } from '../promotion-card/promotion-card.component';
@Component({
  selector: 'app-carousel-promotions',
  templateUrl: './carousel-promotions.component.html',
  styleUrls: ['./carousel-promotions.component.scss']
})
export class CarouselPromotionsComponent implements OnInit {

  constructor() { }
  @ViewChild('CarouselBlock') carouselBlock: ElementRef<HTMLElement>;
  carouselX: number = 0;
  maxClickedLeft = 2;
  clickedTimes = 0;
  @Input() brand: string;
  arrCards: Array<IPromotionCard> = [];
  ngOnInit(): void {
    switch (this.brand) {
      case 'tsars':
        this.arrCards = [
          { title: 'September Equinox1', date: 'Published: 01 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox2', date: 'Published: 02 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox3', date: 'Published: 03 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox4', date: 'Published: 04 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
        ];
        break;
      case 'wisho':
        this.arrCards = [
          { title: 'September Equinox1', date: 'Published: 01 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox2', date: 'Published: 02 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox3', date: 'Published: 03 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox4', date: 'Published: 04 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' }
        ];
        break;
      case 'casoo':
        this.arrCards = [
          { title: 'September Equinox1', date: 'Published: 01 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox2', date: 'Published: 02 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox3', date: 'Published: 03 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox4', date: 'Published: 04 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' }
        ];
        break;
      case 'winnerz':
        this.arrCards = [
          { title: 'September Equinox1', date: 'Published: 01 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox2', date: 'Published: 02 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox3', date: 'Published: 03 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' },
          { title: 'September Equinox4', date: 'Published: 04 Jan 2023', visitLink: 'https://tsars.com', downloadLink: 'https://tsars.com', urlImg: '../../../../assets/images/brands/wisho/promotions/september.png' }
        ];
        break;
    }
    this.maxClickedLeft = Math.ceil(this.arrCards.length / 1.5);
  }
  ngAfterChecked(){

  }
  moveCarousel(isLeft: boolean) {
    let newPos: number;
    if (isLeft) {
      if (this.clickedTimes == this.maxClickedLeft) return;
      newPos = this.carouselX - 500
      this.clickedTimes += 1;
    }
    else {
      if (this.clickedTimes == 0) return;
      newPos = this.carouselX + 500
      this.clickedTimes -= 1;
    }
    this.carouselBlock.nativeElement.animate([
      { transform: "translateX(" + this.carouselX + "px)" },
      { transform: "translateX(" + newPos + "px)" },
    ],
      {
        duration: 300,
        delay: 1,
        fill: "forwards",
        easing: 'linear'
      });
    this.carouselX = newPos;

  }
}
