<div (click)="openDescription($event)" class="brand-description-card-2">
  <div class="header">
    <p class="title-card">{{titleCard}}</p>
    <div #buttonOpen class="close-icon-container">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.34405 17.4862L0.515625 14.6578L6.17248 9.00091L0.515625 3.34405L3.34405 0.515625L9.00091 6.17248L14.6578 0.515625L17.4862 3.34405L11.8293 9.00091L17.4862 14.6578L14.6578 17.4862L9.00091 11.8293L3.34405 17.4862Z" fill="white" />
      </svg>
    </div>
  </div>
  <div *ngIf="isShow" class="content">
    <div class="line" *ngIf="title1">
      <p class="title">{{title1}}</p>
      <p class="subtitle">{{subtitle1}}</p>
      <div class="copy-icon-container" (click)="copyToClipboard(subtitle1)">
        <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z" />
          <path d="M4 8H6V18H16V20H4V8Z" />
        </svg>
      </div>
    </div>
    <div class="line" *ngIf="title2">
      <p class="title">{{title2}}</p>
      <p class="subtitle">{{subtitle2}}</p>
      <div class="copy-icon-container" (click)="copyToClipboard(subtitle2)">
        <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z" />
          <path d="M4 8H6V18H16V20H4V8Z" />
        </svg>
      </div>
    </div>
    <div class="line" *ngIf="title3">
      <p class="title">{{title3}}</p>
      <p *ngIf="!subtitle3Name" class="subtitle">{{subtitle3}}</p>
      <p *ngIf="subtitle3Name" class="subtitle">{{subtitle3Name}}<br><br>{{subtitle3}}</p>
      <div class="copy-icon-container" (click)="copyToClipboard(subtitle3Name?subtitle3Name +' '+subtitle3:subtitle3)">
        <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4V16H20V4H8ZM18 6H10V14H18V6Z" />
          <path d="M4 8H6V18H16V20H4V8Z" />
        </svg>
      </div>
    </div>
  </div>
</div>
