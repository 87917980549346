<div class="footer--out-container" data-scroll-section>
  <div class="footer--inner-container">
    <div class="logo-container">
      <img src="../../../../assets/images/icons/svg/logo-solid.svg" alt="">
    </div>
    <div class="row">
      <div class="menu-container">
        <a routerLink="/commissions" class="menu-item" i18n="@@commission_plans">Commissions</a>
        <a routerLink="/brands" class="menu-item" i18n="@@brands">Brands</a>
        <a routerLink="/about-us" class="menu-item" i18n="@@streamers">About us</a>
        <a routerLink="/partners" class="menu-item" i18n="@@partners">Partners</a>
        <a routerLink="/careers" class="menu-item" i18n="@@careers">Careers</a>
        <a href="https://go.spinwise.partners/signup" target="_blank" class="menu-item" i18n="@@sign_up">Sign up</a>
        <a href="https://go.spinwise.partners/login" target="_blank" class="menu-item" i18n="@@careers">Log in</a>
      </div>
      <div class="brands-container">
        <a routerLink="/brands/casoo" class="menu-item">Casoo</a>
        <a routerLink="/brands/tsars" class="menu-item">Tsars</a>
        <a routerLink="/brands/wisho" class="menu-item">Wisho</a>
        <a routerLink="/brands/winnerz" class="menu-item">Winnerz</a>
        <a routerLink="/brands/trickz" class="menu-item">Trickz</a>
      </div>
    </div>
    <div class="links-container jc-c ai-c">
      <div class="d-fl ai-c linkedin-container">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.8333 0H4.16667C1.86583 0 0 1.86583 0 4.16667V15.8333C0 18.1342 1.86583 20 4.16667 20H15.8333C18.135 20 20 18.1342 20 15.8333V4.16667C20 1.86583 18.135 0 15.8333 0ZM6.66667 15.8333H4.16667V6.66667H6.66667V15.8333ZM5.41667 5.61C4.61167 5.61 3.95833 4.95167 3.95833 4.14C3.95833 3.32833 4.61167 2.67 5.41667 2.67C6.22167 2.67 6.875 3.32833 6.875 4.14C6.875 4.95167 6.2225 5.61 5.41667 5.61ZM16.6667 15.8333H14.1667V11.1633C14.1667 8.35667 10.8333 8.56917 10.8333 11.1633V15.8333H8.33333V6.66667H10.8333V8.1375C11.9967 5.9825 16.6667 5.82333 16.6667 10.2008V15.8333Z"
            fill="#656565" />
        </svg>
        <a href="https://www.linkedin.com/company/spinwise-com" target="_blank" class="linkedin-text">LinkedIn</a>
      </div>

      <a href="../../../../assets/cookie-policy.pdf" class="privacy" target="_blank">Cookie policy</a>
      <a href="../../../../assets/privacy-policy.pdf" class="privacy" target="_blank">Privacy policy</a>
      <a href="../../../../assets/terms-and-conditions.pdf" class="cookies" target="_blank">Terms & Conditions</a>
    </div>
    <div class="rights-container">
      <p class="rights-text">© Spinwise. All rights reserved 2024.</p>
      <div class="d-fl" style="gap:21px">
        <a href="../../../../assets/privacy-policy.pdf" class="privacy" target="_blank">Privacy policy</a>
        <a href="../../../../assets/terms-and-conditions.pdf" class="cookies" target="_blank">Terms & Conditions</a>
      </div>
      <a href="../../../../assets/cookie-policy.pdf" class="privacy" target="_blank">Cookie policy</a>
    </div>

  </div>

</div>