import { Component, Input, OnInit } from '@angular/core';
import { IconTypes } from '../../elements/text-button/text-button.component';


@Component({
  selector: 'app-title-and-subtitle',
  templateUrl: './title-and-subtitle.component.html',
  styleUrls: ['./title-and-subtitle.component.scss']
})
export class TitleAndSubtitleComponent implements OnInit {

  @Input('title') title: string;
  @Input('title2') title2: string;
  @Input('subtitle') subtitle: string;
  @Input('description') description: string;
  @Input('primaryIcon') primaryIcon: boolean = false;
  @Input('primaryIconType') iconType: IconTypes = IconTypes.Arrow;
  @Input('primaryButtonText') primaryButtonText: string;
  @Input('hasExternalLink') hasExternalLink: boolean = false;
  @Input('externalUrl') externalUrl: string;
  @Input('primaryUrl') primaryUrl: string;
  @Input() arrow:boolean = false;
  @Input() fullWidth:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
