import { Component, Input, OnInit } from '@angular/core';
export enum EPhotoSrc{
  Alvis = '../../../../../assets/images/team-photos/example.jpg',
  Peter = '../../../../../assets/images/team-photos/example.jpg',
  Andrejs = '../../../../../assets/images/team-photos/example.jpg',
  Kristaps = '../../../../../assets/images/team-photos/example.jpg',
}
export interface IPhotosCardComponent {
  photoSrc: EPhotoSrc;
  name: string;
  position: string;
}
@Component({
  selector: 'app-photos-card',
  templateUrl: './photos-card.component.html',
  styleUrls: ['./photos-card.component.scss']
})
export class PhotosCardComponent implements OnInit {
  @Input('photoSrc') photoSrc: string;
  @Input('name') name: string;
  @Input('position') position: EPhotoSrc;
  constructor() { }

  ngOnInit(): void {
  }

}
