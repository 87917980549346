<div class="d-fl ai-c counter-container">
  <div class="counter-container--left d-fl ai-c">
    <p class="open-position__counter">{{ this.countPositions}} open positions</p>
    <p class="open-position__in">in</p>
  </div>
  <div class="d-fl jc-sb ai-c city-selector">
    <p #subMenuBtn (click)="openDropdown()">{{selectedCity}}</p>
    <div #subMenuBtnIcon class="icon-container" (click)="openDropdown()">
      <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-down-small.svg" [stretch]="true" svgClass="arrow"
        [svgStyle]="{'fill':'#101820','pointerEvents':'none'}"></svg-icon> -->
      <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" fill="#101820" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8766 9.28859L11.9966 13.1686L8.11656 9.28859C7.72656 8.89859 7.09656 8.89859 6.70656 9.28859C6.31656 9.67859 6.31656 10.3086 6.70656 10.6986L11.2966 15.2886C11.6866 15.6786 12.3166 15.6786 12.7066 15.2886L17.2966 10.6986C17.6866 10.3086 17.6866 9.67859 17.2966 9.28859C16.9066 8.90859 16.2666 8.89859 15.8766 9.28859Z" fill="#101820" />
      </svg>
    </div>
    <div *ngIf="this.showDropdown == true" class="dropdown-container d-fl fd-c">
      <ng-container *ngFor="let item of dropdownItems">
        <div (click)="selectItem(item)" class="dropdown-item">
          <p>{{item.name}}</p>
        </div>
      </ng-container>

    </div>
  </div>
</div>
