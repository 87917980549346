import { Component, Input, OnInit } from '@angular/core';

export enum EPlanCardSvgName {
  circle1 = 'circle1',
  circle2 = 'circle2',
  circle3 = 'circle3'
}
export interface IPlanCard{
  title:string,
  subtitle:string,
  liArr:Array<string>,
  svgName:EPlanCardSvgName,
  isPrimary?:boolean
}
@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {
  @Input() title:string;
  @Input() subtitle:string;
  @Input() liArr:Array<string>;
  @Input() svgName:EPlanCardSvgName = EPlanCardSvgName.circle1;
  @Input() isPrimary:boolean = false;

  @Input() styleBlocks:string
  constructor() { }

  ngOnInit(): void {
    if(this.isPrimary == undefined) this.isPrimary = false;
  }

}
