import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
export interface IBrandTopTitle2 {
  title: string,
  subtitle: string,
  subtitleLink: string,
  downloadLink: String;
}
@Component({
  selector: 'app-brand-top-title-2',
  standalone: false,
  templateUrl: './brand-top-title-2.component.html',
  styleUrl: './brand-top-title-2.component.scss'
})
export class BrandTopTitle2Component {
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;
  @Input('subtitleLink') subtitleLink: string;
  @Input('downloadTitle') downloadTitle: string;
  @Input('downloadLink') downloadLink: string;
}
