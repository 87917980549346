<div class="brand-feature-block-2" (click)="openModal($event)">
  <div class="close-icon-container">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.34405 17.4862L0.515625 14.6578L6.17248 9.00091L0.515625 3.34405L3.34405 0.515625L9.00091 6.17248L14.6578 0.515625L17.4862 3.34405L11.8293 9.00091L17.4862 14.6578L14.6578 17.4862L9.00091 11.8293L3.34405 17.4862Z" fill="white" />
    </svg>
  </div>
  <img class="card-img" src="{{urlImg}}" alt="">
  <div>
    <p class="title">{{title}}</p>
    <p class="description">{{text}}</p>
  </div>
</div>
