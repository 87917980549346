import { Component, Input, OnInit } from '@angular/core';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary'
}
export enum IconTypes {
  Arrow = 'arrow-button',
  Download = 'download-button'
}

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent implements OnInit {

  @Input('type') type: ButtonType = ButtonType.Primary;
  @Input('iconType') iconType: string = IconTypes.Arrow;
  @Input('text') text: string = null;
  @Input('url') url: string = null;
  @Input() brand:string = 'tsars';

  constructor() { }

  ngOnInit(): void {
  }

}
