<div class="affiliate-section__item move-down__{{moveDown}} d-fl jc-c ai-c fd-c">
  <div class="img-container d-fl jc-c ai-c">
    <img *ngIf="iconName == 'dollar'" src="../../../../../assets/images/icons/svg/affiliate/carryover.svg" alt="">

    <img *ngIf="iconName == 'smile'" src="../../../../../assets/images/icons/svg/affiliate/fees.svg" alt="">

    <img *ngIf="iconName == 'creditCard'" src="../../../../../assets/images/icons/svg/affiliate/payment.svg" alt="">

    <img *ngIf="iconName == 'search'" src="../../../../../assets/images/icons/svg/affiliate/transparent.svg" alt="">
  </div>
  <h3 class="fw-800 t-c-4">{{title}}</h3>
  <p *ngIf="title === 'Vast payments'" class="fw-500 t-c-4">{{subtitle}}</p>
  <p *ngIf="title != 'Vast payments'" class="fw-500 t-c-4 last-item">{{subtitle}}</p>
  <div *ngIf="title === 'Vast payments'" routerLink="/partners" class="read-more-block d-fl  ai-c">
    <p class="t-c-4 fw-600 fs-16">read more</p>
    <!-- <svg-icon src="../../../../../assets/images/icons/svg/arrow-diagonal.svg" [stretch]="true"
      [svgStyle]="{'fill':'rgb(0,0,0)'}"></svg-icon> -->
    <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9713 17.9706V6.65685H6.6576L6.63992 8.65443L14.5595 8.65443L5.95049 17.2635L7.36471 18.6777L15.9737 10.0686V17.9882L17.9713 17.9706Z" />
    </svg>

  </div>
