<div class=" container-rounded testimonials__container brand-bg-color__{{brand}}">
  <!-- container-wide calss -->
  <div class="testimonials__container--centered d-fl fd-c jc-sb ai-c">
    <div class="testimonials__header gutter-container brand-title-color__{{brand}}" data-scroll>
      <h1 class="testimonials__title">What they say</h1>
      <h3 class="testimonials__subtitle">testimonials</h3>
      <div *ngIf="getReviews().length > 0" class="testimonials__navigation gutter-container d-fl jc-sb" data-scroll data-scroll-speed="1.2">
        <div (click)="moveLeft()" class="btn btn--left d-fl ai-c jc-c btn-container {{isFirst}}">
          <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true"
            [svgStyle]="{'fill':'#FFF','padding':'12px'}"></svg-icon> -->
          <svg width="48" height="48" viewBox="0 0 48 48" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
          </svg>
        </div>
        <div (click)="moveRight()" class="btn d-fl ai-c jc-c btn-container {{isLast}}">
          <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true"
            [svgStyle]="{'fill':'#FFF','padding':'12px'}"></svg-icon> -->
          <svg width="48" height="48" viewBox="0 0 48 48" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
          </svg>
        </div>
      </div>
    </div>
    <div #testimonials class="testimonials__content d-fl jc-fs" data-scroll data-scroll-direction="horizontal">
      <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="testimonial__item brand-card-color__{{brand}}" *ngFor="let review of getReviews(); index as i;">
        <a href="{{review.url}}" target="_blank" style="text-decoration: none;">
          <div class="icon-container">
            <!-- <svg-icon src="../../../../assets/images/icons/svg/quotes-big.svg" [stretch]="true"
              [svgStyle]="{'fill':'#E7E7E7'}"></svg-icon> -->
            <svg width="48" height="48" viewBox="0 0 48 48" fill="#E7E7E7" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.36 7L14.36 31.36L12.92 24.4C15.24 24.4 17.16 25.12 18.68 26.56C20.2 28 20.96 29.92 20.96 32.32C20.96 34.72 20.2 36.68 18.68 38.2C17.16 39.72 15.28 40.48 13.04 40.48C10.72 40.48 8.8 39.72 7.28 38.2C5.76 36.6 5 34.64 5 32.32C5 31.52 5.04 30.76 5.12 30.04C5.28 29.32 5.52 28.48 5.84 27.52C6.16 26.48 6.6 25.24 7.16 23.8L12.8 7H20.36ZM43.04 7L37.04 31.36L35.6 24.4C37.92 24.4 39.84 25.12 41.36 26.56C42.88 28 43.64 29.92 43.64 32.32C43.64 34.72 42.88 36.68 41.36 38.2C39.84 39.72 37.96 40.48 35.72 40.48C33.4 40.48 31.48 39.72 29.96 38.2C28.44 36.6 27.68 34.64 27.68 32.32C27.68 31.52 27.72 30.76 27.8 30.04C27.96 29.32 28.2 28.48 28.52 27.52C28.84 26.48 29.28 25.24 29.84 23.8L35.48 7H43.04Z" />
            </svg>

          </div>
          <p>{{review.review}}</p>
        </a>

        <!-- <h4 *ngIf="withFrom == true" class="fs-16 fw-700">{{review.from}}</h4> -->
        <div>
          <h4 class="fs-16 fw-500" style="margin: 0;padding-bottom: 8px;color:#898989;">{{review.from}}</h4>
          <a href="{{review.url}}" target="_blank" class="t-c-6 fs-16 fw-500 link url-link">{{parseUrl(review.url)}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
