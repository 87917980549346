import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-title',
  templateUrl: './brand-title.component.html',
  styleUrls: ['./brand-title.component.scss']
})
export class BrandTitleComponent implements OnInit {

  constructor() { }
  @Input() title:string;
  @Input() btnText:string;
  @Input() btnUrl:string;
  @Input() brand:string = 'tsars';
  ngOnInit(): void {
  }

}
