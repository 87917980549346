<div style="height: 80px;">

</div>
<section class="centered-container brand-page__container" data-scroll-section>

  <app-brand-top-title-2 title="{{topTitle.title}}" subtitle="{{brand}}" downloadTitle="{{scrollInputs.subtitle}}" subtitleLink="{{topTitle.subtitleLink}}" downloadLink="{{scrollInputs.subtitleHref}}">
  </app-brand-top-title-2>
</section>

<section data-scroll-section class="top-banner-container">
  <app-brand-top-banner-2 estabilished="{{estabilished}}" descriptions="{{topBrandDescriptions}}" brand="{{brand}}">
  </app-brand-top-banner-2>
  <section #featureBlocks data-scroll class="centered-container feature-block" data-scroll-call="sticky-title" data-scroll-repeat="true">
    <p class="section-title">Features</p>
    <div class="feature-container">
      <app-brand-feature-block-2 *ngFor="let featureBlock of featureBlockArr" (iconClickEvent)="openPopup($event)" brand="{{featureBlock.brand}}" title="{{featureBlock.title}}" text="{{featureBlock.text}}" urlImg="{{featureBlock.urlImg}}" popupType="{{featureBlock.popupType}}">
      </app-brand-feature-block-2>
    </div>
  </section>

  <section data-scroll>
    <div class="centered-container brand-descriptions">
      <p class="section-title">Brand</p>
      <div class="brand-description-container">
        <app-brand-description-card-2 *ngFor="let card of descriptionCardArr" titleCard="{{card.titleCard}}" title1="{{card.title1}}" title2="{{card.title2}}" title3="{{card.title3}}" subtitle1="{{card.subtitle1}}" subtitle2="{{card.subtitle2}}" subtitle3="{{card.subtitle3}}"
          subtitle3Name="{{card.subtitle3Name}}" brand="{{card.brand}}">
        </app-brand-description-card-2>
      </div>
    </div>
    <!--TODO: Change color to brand color -->
    <div style="min-width: 100%;
    background: rgba(0,0,0,0.1)">
      <app-testimonials brand="{{brand}}" type="user"></app-testimonials>
    </div>
  </section>
</section>

<div style="padding-top: 1rem;">
  <app-get-in-touch title="Let's talk" subtitle="get in touch">
  </app-get-in-touch>
</div>


<section data-scroll-section>
  <app-contact-us></app-contact-us>
</section>
<section data-scroll-section>
  <app-arrow-banner></app-arrow-banner>

</section>

<app-scroll-banner [scrollInputs]="scrollInputs" dataCall="sticky-title" dataCallClose="sticky-title-close">
</app-scroll-banner>