import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';

const subMenuShowClass: string = 'brands-btn-show';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  activeRoute: string = '';

  @ViewChild('hiddenNav') hiddenNav: ElementRef;
  @ViewChild('desktopHeader') desktopHeader: ElementRef;
  @ViewChild('mobileHeader') mobileHeader: ElementRef;
  @ViewChild('subMenu') subMenu: ElementRef<HTMLElement>;
  @ViewChild('subMenuBtn') subMenuBtn: ElementRef<HTMLElement>;
  // mobile menu
  quetesBig = null;
  showBrands = false;
  showMobileMenu = false;
  @HostListener('document:click', ['$event'])
  clickout = (event) => {
    if (event.target == this.subMenuBtn.nativeElement) {
      this.openSubMenu()
    }
    else {
      this.closeSubMenu();
    }
  }
  constructor(private scroll: ScrollManagerService) {

  }
  ngOnInit(): void {

  }

  isRoute = (route) => {
    return this.activeRoute.indexOf(route) !== -1;
  }

  openNav(cantScroll: boolean = true) {
    this.hiddenNav.nativeElement.classList.add('open');
    if (cantScroll) {
      document.body.classList.add('no-scroll');
    }
  }
  closeNav() {
    this.hiddenNav.nativeElement.classList.remove('open');
    document.body.classList.remove('no-scroll');
  }

  contactUs() {
    this.closeSubMenu();
    let offset = -200;
    const contactUs = document.querySelector('#contact-us-section') as HTMLElement;
    const { dataset } = contactUs;
    if (dataset.offset) {
      offset += parseInt(dataset.offset);
    }

    this.scroll.scrollToTarget(contactUs, offset);
  }
  contactUsMobile() {
    const contactUs = document.querySelector('#contact-us-section');
    this.showSideBar(false);
    //contactUs.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.scroll.scrollToTarget(contactUs, -1300);
  }
  showSideBar(isShow: boolean) {
    if (isShow == true) {
      this.scroll.scroll.stop();
      this.showMobileMenu = true;
      document.body.classList.add('no-scroll');
      this.desktopHeader.nativeElement.classList.add('open');
    } else {
      this.showMobileMenu = false;
      this.scroll.scroll.start();
      this.showBrands = false;
      document.body.classList.remove('no-scroll');
      this.desktopHeader.nativeElement.classList.remove('open');
    }
  }
  setShowBrands() {
    this.showBrands = !this.showBrands;

  }
  scrollUp() {
    const header = document.getElementById('titleSubtitle');
    this.scroll.scrollToTarget(header, -150);
  }
  routerMove(routerLink: string) {
    this.scrollUp();
    this.closeSubMenu();
    this.showSideBar(false);
    // this.router.navigate([routerLink]);
  }
  closeSubMenu = () => {
    if (this.subMenu.nativeElement.classList.contains(subMenuShowClass)) {
      this.subMenu.nativeElement.classList.remove(subMenuShowClass);

    }
  }
  openSubMenu = () => {
    this.subMenu.nativeElement.classList.add(subMenuShowClass);

  }
}
