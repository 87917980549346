<div *ngIf="data" class="modal-bg" #bgElement (click)="onClickClose($event)">
  <div class="modal-content" #content>
    <div #buttonOpen class="close-icon-container" (click)="onClickClose($event,true)">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.34405 17.4862L0.515625 14.6578L6.17248 9.00091L0.515625 3.34405L3.34405 0.515625L9.00091 6.17248L14.6578 0.515625L17.4862 3.34405L11.8293 9.00091L17.4862 14.6578L14.6578 17.4862L9.00091 11.8293L3.34405 17.4862Z" fill="white" />
      </svg>
    </div>
    <img class="img-top" src="{{getTopImage()}}" alt="">
    <div class="middle">
      <p class="title">{{data.title}}</p>
      <p class="description">{{data.description}}</p>
    </div>
    <div *ngIf="data.cards?.length > 0" class="cards cards-grid--{{data.cards.length == 1?'one':data.cards.length == 3?'three':'two'}}">
      <div class="card-container" *ngFor="let card of data.cards">
        <img class="card-image" src="{{card.src}}" alt="">
        <p class="card-title">{{card.title}}</p>
        <p class="card-description">{{card.description}}</p>
      </div>
    </div>
  </div>
</div>
