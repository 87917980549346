import { CmsService, IPosition } from 'src/app/services/cms.service';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
import { EScrollBannerIcons, IScrollBannerComponent } from '../../shared/scroll-banner/scroll-banner.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-open-position',
  templateUrl: './open-position.component.html',
  styleUrls: ['./open-position.component.scss']
})
export class OpenPositionComponent implements OnInit {

  @ViewChild('arrow') arrow;
  @ViewChild('positions') positions;

  position: string;
  location: string = '';

  content: string;
  isLoading: boolean;
  positionName: string;
  positionSlug: string = '';

  benefitMap: any = {
    tallinn: {
      courses: true,
      remote: false,
      insurance: true,
      lunch: false,
      brunch: true,
      short_friday: false,
      birthday: true,
    },
    riga: {
      courses: true,
      remote: true,
      insurance: true,
      lunch: true,
      brunch: false,
      short_friday: true,
      birthday: true,
    },
    limassol: {
      courses: true,
      remote: true,
      insurance: false,
      lunch: false,
      brunch: false,
      short_friday: true,
      birthday: true,
    },
    malta: {
      courses: true,
      remote: true,
      insurance: false,
      lunch: false,
      brunch: false,
      short_friday: true,
      birthday: true,
    }
  }

  constructor(private route: ActivatedRoute, private cmsService: CmsService, private scrollManagerService: ScrollManagerService) {
    this.position = this.route.snapshot.paramMap.get('position');
    this.cmsService.getOpenPosition(this.position);

  }


  ngOnInit(): void {

    this.isLoading = true;

    this.cmsService.openPositions$.subscribe((data) => {
      if (data.length > 0) {
        // this.content = data[0].content.rendered;
        // this.positionName = data[0].title.rendered;
        // this.positionSlug = data[0].slug;
        // this.location = data[0].x_tags;
        // this.isLoading = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.scrollManagerService.registerCallback("show-arrow", this.showArrow);
    this.scrollManagerService.registerCallback("hide-arrow", this.hideArrow);

    this.route.params.subscribe(params => {
      //window.location.reload();
      this.cmsService.getOpenPosition(params['position']).subscribe((data) => {
        if (data.length > 0) {
          this.content = data[0].content.rendered;
          this.positionName = data[0].title.rendered;
          this.positionSlug = data[0].slug;
          this.location = data[0].x_tags;
          this.isLoading = false;
        }
      });
    });
  }

  showArrow = (type, el) => {
    switch (type) {
      case 'exit':
        this.arrow.show();
        break;
    }
  }

  hideArrow = (type, el) => {
    switch (type) {
      case 'enter':
        this.arrow.hide();
        break;
    }
  }
}
