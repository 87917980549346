<div class="clear-cut--block d-fl fd-c-r-m jc-fs ai-fs block--{{styleBlocks}} color-primary__{{isPrimary}} fade-in"
  data-scroll data-scroll-offset="35%">

  <div class="clear-cut--block-item ">
    <div class="container-top">
      <!-- 1 -->
      <img *ngIf="svgName == 'circle1'" src="../../../../../assets/images/icons/svg/plans/rs.svg" alt="">
      <!-- 2 -->
      <img *ngIf="svgName == 'circle2'" src="../../../../../assets/images/icons/svg/plans/cpm.svg" alt="">

      <!-- 3 -->
      <img *ngIf="svgName == 'circle3'" src="../../../../../assets/images/icons/svg/plans/hybrid.svg" alt="">

      <h3 class="t-c-4 fs-19 fw-800">{{title}}</h3>
      <p *ngIf="liArr != undefined" class="t-c-4 fs-19 fw-500">{{subtitle}}</p>
      <p *ngIf="liArr == undefined" class="t-c-4 fs-19 fw-500 subtitle">{{subtitle}}</p>
    </div>
    <div *ngIf="liArr != undefined" class="container-bottom">
      <ul>
        <li *ngFor="let li of liArr;let i = index">{{liArr[i]}}</li>
      </ul>
    </div>
  </div>
</div>
