import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
@Component({
  selector: 'app-brand-banner',
  templateUrl: './brand-banner.component.html',
  styleUrls: ['./brand-banner.component.scss']
})
export class BrandBannerComponent implements OnInit {

  @Input('brand_banner') brand_banner;
  @Input('brand_name') brand_name = 'tsars';
  @Input('brand_url_character') brand_url_character
  @Input('brand_slogan') brand_slogan;

  @ViewChild('BgContainer') bgContainer:ElementRef;
  webHref: string;
  brand: string;
  characterUrl: string;
  logo:string;
  constructor(public scrollManagerService: ScrollManagerService) { }

  ngOnInit(): void {
    this.webHref = `https://www.${this.brand_name.toLowerCase()}.com`;
    this.brand = this.brand_name.toLowerCase();
    this.characterUrl = `../../../../assets/images/characters/character-${this.brand_name.toLowerCase()}.png`;
    this.logo = `../../../../assets/images/logos/${this.brand}.png`
  }
  ngAfterViewInit(){
    this.bgContainer.nativeElement.classList.add(`brand__${this.brand_name.toLowerCase()}`);
  }
  scrollUp(){
    const header = document.getElementById('titleSubtitle');
    this.scrollManagerService.scrollToTarget(header,-150);
  }
}
