import { CmsService, IPosition } from 'src/app/services/cms.service';
import { PositionsComponent } from '../../blocks/positions/positions.component';
import { IDropdownMenuCities } from './dropdown-menu/dropdown-menu.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss']
})
export class HiringComponent implements OnInit {
  isLoading: boolean = true;
  @ViewChild(PositionsComponent) child: PositionsComponent

  location: string = 'all locations';
  locationLong: string = 'all locations';
  openPositions: IPosition[] = [];

  locationMap = {
    'riga': 'Riga',
    'tallinn': 'Tallinn',
    'limassol': 'Limassol',
    'malta': 'Malta',
    'all locations': 'All locations'
  };

  countPositions: number = 0;
  constructor(private route: ActivatedRoute, private cmsService: CmsService) {
    const _location = this.route.snapshot.paramMap.get('location');
    this.location = !!_location ? _location : this.location;
    this.cmsService.setLocation(this.location);
  }

  onSelectUpdate(e: IDropdownMenuCities) {
    this.location = e.name.toLowerCase();
    this.locationLong = e.longName;
    this.cmsService.setLocation(this.location);
    this.countPositions = this.child.openPositions.length;
    this.child.filterPositions(this.locationMap[this.location]);
  }
  ngOnInit(): void {
    this.cmsService.positions$.subscribe((data) => {
      if (!data) return;

      this.isLoading = false;
      this.openPositions = data;
      this.countPositions = this.openPositions.length;
    });
  }


}
