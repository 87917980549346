import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-top-title',
  templateUrl: './brand-top-title.component.html',
  styleUrls: ['./brand-top-title.component.scss']
})
export class BrandTopTitleComponent implements OnInit {
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;
  @Input('subtitleLink') subtitleLink: string;
  @Input('downloadTitle') downloadTitle: string;
  @Input('downloadLink') downloadLink: string;

  @Input('isDownload') isDownload?: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
