<div class="plan-block-title d-fl ai-c jc-sb">
  <div class="plan-block-title--left">
    <app-page-title title="Clear Cut" subtitle="commissions" description="We guarantee to deliver an even-handed revenue share, regardless of which plan you choose.">
    </app-page-title>
  </div>
  <a *ngIf="withDescription == true" target="_blank" href="https://go.spinwise.partners/signup" class="plan-block-title--right-text d-fl ai-c">
    <p class="btn-title">Sign up</p>
    <!-- <svg-icon src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true" svgClass="arrowBtn">
    </svg-icon> -->
    <svg class="arrowBtn" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
    </svg>


  </a>
  <div *ngIf="withDescription == false" routerLink="/commissions" class="plan-block-title--right d-fl ai-c">
    <!-- <svg-icon  routerLinkActive="active-link"
      src="../../../../assets/images/icons/svg/arrow-right-big.svg" [stretch]="true" svgClass="arrowBtn">
    </svg-icon> -->
    <svg routerLinkActive="active-link" class="arrowBtn" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8L40 24L24 40L21.15 37.2L32.35 26L8 26L8 22L32.35 22L21.15 10.8L24 8Z" />
    </svg>
  </div>
</div>