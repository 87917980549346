
<div>
     <app-page-title
     title="No match?"
     subtitle="Get in touch"
     position="center"
     description="Even if you cannot see the perfect vacancy for you, it doesn't
     mean that we don't want to see you on our team. Get in
     touch and see if there is a way we can work together.">
</app-page-title>
</div>
<div class="no-match__cta">
     <a href="mailto: hr@spinwise.com" class="btn btn--primary">Drop us a line</a>
</div>
