<div class="d-fl fd-c ai-c positions__container">
    <div class="page-title--medium" *ngIf="hidePageHeaders == false">
        <h2>{{title}}</h2>
        <h3>{{subtitle}}</h3>
    </div>
    <ng-container *ngFor="let pos of openPositions | callback: filterCurrent">
        <a routerLink="/careers/hiring/{{pos.slug}}" class="open-position__container">
            <div>
                <h2>{{pos.title}}</h2>
                <div class="open-position__info">
                    <p>{{pos.location}}</p>
                    <span class="dot dot--gray"></span>
                    <p>{{pos.schedule}}</p>
                </div>
            </div>
            <div class="position--arrow">
                <img src="../../../../assets/images/icons/svg/arrow-right.svg" />
            </div>
        </a>
    </ng-container>
    <a *ngIf="viewAll" routerLink="/careers/hiring" class="btn btn--primary view-all">View all</a>
</div>
