import { Component, OnInit } from '@angular/core';
import { ESvgIcon, IAffiliateCard } from '../affiliate-card/affiliate-card.component';

@Component({
  selector: 'app-affiliate-blocks',
  templateUrl: './affiliate-blocks.component.html',
  styleUrls: ['./affiliate-blocks.component.scss']
})
export class AffiliateBlocksComponent implements OnInit {

  constructor() { }
  affiliateCardArr:Array<IAffiliateCard> = []
  ngOnInit(): void {
    this.affiliateCardArr = [
      {title:'No negative carryover',subtitle:'Our solution is designed to be safe - no need to worry about your balance going negative',iconName:ESvgIcon.Dollar},
      {title:'Zero fees',subtitle:'Spinwise pays partners their commissions based on Net Gaming Revenue (NGR) without any fees applied',iconName:ESvgIcon.Smile},
      {title:'Vast payments',subtitle:'Our vast payment method portfolio allows to settle commissions in various ways and currencies',iconName:ESvgIcon.CreditCard},
      {title:'Transparency',subtitle:'Partner data updates daily and every single transaction of a player is visible in the partners panel',iconName:ESvgIcon.Search},

    ]
  }

}
