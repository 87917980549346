<section data-scroll data-scroll-section class="open-position__page section__end">
    <div class="open-position--hero__bg" *ngIf="!isLoading" data-scroll data-scroll-repeat="true" data-scroll-call="show-arrow" data-scroll-offset="80%, 0"></div>
    <div class="gutter-container open-position--content-wrap" [innerHTML]="content"></div>
    <div class="centered-container gutter-container" *ngIf="!isLoading">
        <h2 class="we-offer__title">What's it like working here</h2>
        <div class="we-offer__container">
            <div class="we-offer__block" *ngIf="benefitMap[location].courses">
                <img src="../../../../assets/images/icons/svg/offers/learn.svg" />
                <p class="we-offer__title">Courses and lectures</p>
                <p class="we-offer__description">to explore new horizons on your own or in groups</p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].remote">
                <img src="../../../../assets/images/icons/svg/offers/remote.svg" />
                <p class="we-offer__title">Remote work</p>
                <p class="we-offer__description">work where you want so you can give it your all</p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].insurance">
                <img src="../../../../assets/images/icons/svg/offers/health.svg" />
                <p class="we-offer__title">Health Insurance</p>
                <p class="we-offer__description">focus on the fun, and we take care of the rest - including your health
                </p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].brunch">
                <img src="../../../../assets/images/icons/svg/offers/health.svg" />
                <p class="we-offer__title">Snacks and brunches</p>
                <p class="we-offer__description">waiting for you at the office
                </p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].lunch">
                <img src="../../../../assets/images/icons/svg/offers/lunch.svg" />
                <p class="we-offer__title">Paid lunch</p>
                <p class="we-offer__description">along with snacks and beverages waiting for you at the office</p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].short_friday">
                <img src="../../../../assets/images/icons/svg/offers/holidays.svg" />
                <p class="we-offer__title">Shortened days</p>
                <p class="we-offer__description">on Fridays and days
                    before national holidays </p>
            </div>
            <div class="we-offer__block" *ngIf="benefitMap[location].birthday">
                <img src="../../../../assets/images/icons/svg/offers/birthday.svg" />
                <p class="we-offer__title">Your birthday</p>
                <p class="we-offer__description">is always a reason to celebrate with a paid day off</p>
            </div>
        </div>
    </div>
</section>
<section class="container-wide  gutter-container section__end" data-scroll data-scroll-repeat="true" data-scroll-section data-scroll-offset="10%" data-scroll-call="hide-arrow">
    <app-positions [except]="positionSlug" [limit]=4 subtitle="Other positions" [except]="positionSlug"></app-positions>
</section>
<section class="container-wide gutter-container  no-match__container" data-scroll data-scroll-repeat="true" data-scroll-section data-scroll-offset="10%" data-scroll-call="show-arrow" id="contact-us-section" data-offset="110">
    <div class="container-wide d-fl jc-c ai-c" class="no-much-container">
        <div class="d-fl fd-c jc-c ai-c get-in-touch__container">
            <h1 class="text--regular t-c-7 fade-in" data-scroll data-scroll-speed="-0.5">No match?</h1>
            <h2 class="text--regular t-c-4 fade-in" data-scroll data-scroll-speed="0">Get in touch</h2>
        </div>
    </div>
    <div style="width: 100%;">
        <app-contact-us contactOf="hr"></app-contact-us>
    </div>
</section>

<app-arrow-banner-small #arrow [hidden]="true" [bannerTitle]="positionName" actionText="Apply now" actionUrl="mailto:hr@spinwise.com?subject={{positionName}}"></app-arrow-banner-small>