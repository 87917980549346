import { Component, Input, OnInit } from '@angular/core';

export enum ESvgIcon {
  Dollar = 'dollar',
  Smile = 'smile',
  CreditCard = 'creditCard',
  Search = 'search'
}
export interface IAffiliateCard{
  iconName:ESvgIcon,
  title:string,
  subtitle:string,
}
@Component({
  selector: 'app-affiliate-card',
  templateUrl: './affiliate-card.component.html',
  styleUrls: ['./affiliate-card.component.scss']
})
export class AffiliateCardComponent implements OnInit {
  @Input() iconName: ESvgIcon = ESvgIcon.Dollar;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() moveDown:boolean;
  constructor() { }
  ngOnInit(): void {

  }

}
