import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-auto-carousel',
  templateUrl: './auto-carousel.component.html',
  styleUrls: ['./auto-carousel.component.scss'],
})
export class AutoCarouselComponent implements OnInit, AfterViewChecked {
  @Input('images') images: Array<string>;
  @ViewChild('GallaryBlock1') gallaryBlock1: ElementRef<HTMLElement>;
  @Input('roundedCorners') roundedCorners : boolean;

  timerId: number;
  constructor() {}
  ngOnInit(): void {}
  ngAfterViewChecked(): void {

  }
  turnOffTimer() {

  }
}
