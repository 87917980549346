import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EPopupType } from '../popup-bonus/popup-bonus.component';

export interface IFeatureBlock {
  title: string,
  text: string,
  urlImg: string,
  brand: string,
  popupType: EPopupType
}
@Component({
  selector: 'app-brand-feature-block',
  templateUrl: './brand-feature-block.component.html',
  styleUrls: ['./brand-feature-block.component.scss']
})
export class BrandFeatureBlockComponent implements OnInit {
  @ViewChild('FeatuteBlock') featureBlock: ElementRef<HTMLElement>;
  @Input() title: string;
  @Input() text: string;
  @Input() urlImg: string;
  @Input() brand: string = 'tsars'
  @Input() popupType: EPopupType;
  @Output() iconClickEvent = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.featureBlock.nativeElement.classList.add(`brand__${this.brand}`);
  }
  openModal = (e: any) => {

    this.iconClickEvent.emit(this.popupType);
  }
}
