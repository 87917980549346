<div #NavigationCarousel class="d-fl fd-c-r-m jc-sb ai-c brand__features--title brand-conor__{{brand}}">
  <h2  class="t-c-4 text--medium">promotions</h2>
  <div class="d-fl">
    <div (click)="moveCarousel(true)" class="btn d-fl ai-c jc-c btn-container">
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5714 8.39394L3.7828 8.39394L8.74961 12.9594C9.13248 13.3113 9.13248 13.9008 8.74961 14.2527C8.39093 14.5824 7.82546 14.5824 7.46678 14.2527L1.0016 8.30999C0.929588 8.27274 0.861609 8.22571 0.799774 8.16887C0.736042 8.11029 0.682919 8.04512 0.640404 7.97557C0.552533 7.84171 0.5 7.68071 0.5 7.5C0.5 7.31929 0.552533 7.15829 0.640402 7.02443C0.682917 6.95488 0.736041 6.88971 0.799774 6.83113C0.861608 6.77429 0.929587 6.72725 1.00159 6.69001L7.46678 0.747268C7.82546 0.417577 8.39093 0.417577 8.74961 0.747269C9.13248 1.09921 9.13248 1.68867 8.74961 2.04061L3.7828 6.60606L15.5714 6.60606C16.0438 6.60606 16.5 6.96746 16.5 7.5C16.5 8.03254 16.0438 8.39394 15.5714 8.39394Z"
          fill="white" stroke="white" />
      </svg>
    </div>
    <div (click)="moveCarousel(false)" class="btn btn--right d-fl ai-c jc-c btn-container">
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5714 8.39394L3.7828 8.39394L8.74961 12.9594C9.13248 13.3113 9.13248 13.9008 8.74961 14.2527C8.39093 14.5824 7.82546 14.5824 7.46678 14.2527L1.0016 8.30999C0.929588 8.27274 0.861609 8.22571 0.799774 8.16887C0.736042 8.11029 0.682919 8.04512 0.640404 7.97557C0.552533 7.84171 0.5 7.68071 0.5 7.5C0.5 7.31929 0.552533 7.15829 0.640402 7.02443C0.682917 6.95488 0.736041 6.88971 0.799774 6.83113C0.861608 6.77429 0.929587 6.72725 1.00159 6.69001L7.46678 0.747268C7.82546 0.417577 8.39093 0.417577 8.74961 0.747269C9.13248 1.09921 9.13248 1.68867 8.74961 2.04061L3.7828 6.60606L15.5714 6.60606C16.0438 6.60606 16.5 6.96746 16.5 7.5C16.5 8.03254 16.0438 8.39394 15.5714 8.39394Z"
          fill="white" stroke="white" />
      </svg>
    </div>
  </div>
</div>
<div #Carousel class="centered-container brand__promotions">
  <div class="d-fl carousel">
    <div #CarouselBlock class="carousel-block d-fl">
      <div class="carousel-item" *ngFor="let card of arrCards">
        <app-promotion-card title="{{card.title}}" date="{{card.date}}" visitLink="{{card.visitLink}}"
          downloadLink="{{card.downloadLink}}" urlImg="{{card.urlImg}}" brand="{{brand}}">
        </app-promotion-card>
      </div>
    </div>
  </div>
</div>
