<div class="brand-top-banner-2 brand-bg-color__{{brand}}">
  <div class="inner-container">
    <div class="content">
      <img class="logo" src="../../../../../assets/images/logos/{{brand}}.png" alt="">
      <p class="text text-color__{{brand}}">{{descriptions}}</p>
      <br>
      <p class="text text-color__{{brand}}">{{estabilished}}</p>
    </div>
  </div>
</div>
