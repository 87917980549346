import { DataService } from './services/data.service';
import { ResizeObserver } from '@juggle/resize-observer';
import { ScrollManagerService } from './services/scroll-manager.service';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Spinwise.com';
  whitelistLanguages: any[] = ['en', 'at', 'es', 'ru'];
  currentLanguage: string = 'en';
  scroll;

  @ViewChild('scrollContent') scrollContent: ElementRef;

  animation: any;
  stepWidth: any = 605;
  stepHeight: any = 461;
  stepPercent: any = .75;
  showHeader: boolean = true;

  constructor(private dataService: DataService,
    private scrollManagerService: ScrollManagerService, private router: Router) {
      const header = document.getElementById('header');
      this.router.events.subscribe((val: any) => {
        document.body.dataset.theme = '';
        if (val && val.url) {
          if (val.url !== '/') {
            document.body.dataset.skin = 'shadow';
          } else {
            document.body.dataset.skin = '';
          }
          if (val.url === '/partners/event') {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }

        }


      })
  }

  ngOnInit() {

    // get path without /
    let path = window.location.href.replace(window.location.origin, '').replace(/\//g, '');
    // take first 2 simbols representing the language
    const lang = path.slice(0, 2);
    if (this.whitelistLanguages.indexOf(lang) !== -1) {
      this.currentLanguage = lang;
    }
    this.dataService.updateCurrentLanguage(this.currentLanguage);
  }


  ngAfterViewInit() {

    const ro = new ResizeObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        if (this.scrollManagerService.scroll) {
          this.scrollManagerService.updateScroll(!this.scrollManagerService.ignoreResize);
        }
      });
    });

    ro.observe(this.scrollContent.nativeElement);
  }

  @HostListener('window:load')
  init() {
    this.scrollManagerService.initLocomotiveScroll();
    this.scrollManagerService.registerCallback("updateHeader", this.updateHeader);
  }

  stickyPosition: 0;


  updateHeader = (type) => {
    const header = document.querySelector('#header');

    if (type == "enter") {
      header.classList.add('with-border');
      document.body.dataset.theme = '';
    } else {
      //header.classList.remove('with-border');
      // if (!header.classList.contains('with-title'))
      //   header.classList.remove('with-border');
    }
  }
  prevProgress: any = 0;

}
