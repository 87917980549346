import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-arrow-banner',
  templateUrl: './arrow-banner.component.html',
  styleUrls: ['./arrow-banner.component.scss']
})
export class ArrowBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
