import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
export interface IPromotionCard{
  title:string,
  date:string,
  visitLink:string,
  downloadLink:string,
  urlImg:string
}
@Component({
  selector: 'app-promotion-card',
  templateUrl: './promotion-card.component.html',
  styleUrls: ['./promotion-card.component.scss']
})

export class PromotionCardComponent implements OnInit {
  @Input() title:string;
  @Input() date:string;
  @Input() visitLink:string;
  @Input() downloadLink:string;
  @Input() urlImg:string;
  @Input() brand:string = 'tsars'
  @ViewChild('PromotionCard') promotionCard:ElementRef<HTMLElement>;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    this.promotionCard.nativeElement.classList.add(`brand__${this.brand}`);
  }
}
