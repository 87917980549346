<section class="centered-container section__start brand-page__container" data-scroll-section>
  <app-page-title title="Fun is our business" subtitle="Our Story"
    description="Founded by a group of industry veterans and a stellar team, Spinwise lives and breathes gambling. Our brand mission statement is “It’s time to take fun more seriously” because the entertainment industry is serious business. All of the Spinwise brands are top performers in their markets, and we ensure our state of the art product along with our exceptional team keep it that way. Spinwise consists of brilliant individuals who are driven to become leaders in the entertainment industry and are already succeeding at it."
    position="left"></app-page-title>
</section>

<section class="figure-container" data-scroll-section>
  <div class="card-container">
    <p class="figure-title centered-container">Spinwise in figures</p>
    <div class="figure-card">
      <p class="card-title">150k+</p>
      <p class="card-subtitle">registered</p>
      <p class="card-date">as of Jan 1, 2022</p>
    </div>
    <div class="figure-card">
      <p class="card-title">4k+</p>
      <p class="card-subtitle">affiliates</p>
      <p class="card-date">as of Jan 1, 2022</p>
    </div>
    <div class="figure-card">
      <p class="card-title">3m+</p>
      <p class="card-subtitle">visitors</p>
      <p class="card-date">as of Jan 1, 2022</p>
    </div>
    <div class="figure-card last-card">
      <p class="card-title">23</p>
      <p class="card-subtitle">countries</p>
      <p class="card-date">as of Jan 1, 2022</p>
    </div>
  </div>
</section>
<section class="markets-container" data-scroll-section>
  <div class="text-container title-subtitle-container centered-container">
    <p class="title">Key Markets</p>
    <p class="subtitle">Spinwise took the world by storm from its very beginning. Now we have millions of customers
      worldwide meaning millions of smiles all over the world and smiles are our currency. Come on this exciting journey
      with us. First destination - our key markets.</p>
  </div>
  <div class="markets__navigation d-fl jc-sb">
    <div (click)="moveLeft()" class="btn d-fl ai-c jc-c btn-container {{isFirst}}">
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5714 8.39394L3.7828 8.39394L8.74961 12.9594C9.13248 13.3113 9.13248 13.9008 8.74961 14.2527C8.39093 14.5824 7.82546 14.5824 7.46678 14.2527L1.0016 8.30999C0.929588 8.27274 0.861609 8.22571 0.799774 8.16887C0.736042 8.11029 0.682919 8.04512 0.640404 7.97557C0.552533 7.84171 0.5 7.68071 0.5 7.5C0.5 7.31929 0.552533 7.15829 0.640402 7.02443C0.682917 6.95488 0.736041 6.88971 0.799774 6.83113C0.861608 6.77429 0.929587 6.72725 1.00159 6.69001L7.46678 0.747268C7.82546 0.417577 8.39093 0.417577 8.74961 0.747269C9.13248 1.09921 9.13248 1.68867 8.74961 2.04061L3.7828 6.60606L15.5714 6.60606C16.0438 6.60606 16.5 6.96746 16.5 7.5C16.5 8.03254 16.0438 8.39394 15.5714 8.39394Z"
          fill="white" stroke="white" />
      </svg>
    </div>
    <div (click)="moveRight()" class="btn btn--right d-fl ai-c jc-c btn-container {{isLast}}">
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5714 8.39394L3.7828 8.39394L8.74961 12.9594C9.13248 13.3113 9.13248 13.9008 8.74961 14.2527C8.39093 14.5824 7.82546 14.5824 7.46678 14.2527L1.0016 8.30999C0.929588 8.27274 0.861609 8.22571 0.799774 8.16887C0.736042 8.11029 0.682919 8.04512 0.640404 7.97557C0.552533 7.84171 0.5 7.68071 0.5 7.5C0.5 7.31929 0.552533 7.15829 0.640402 7.02443C0.682917 6.95488 0.736041 6.88971 0.799774 6.83113C0.861608 6.77429 0.929587 6.72725 1.00159 6.69001L7.46678 0.747268C7.82546 0.417577 8.39093 0.417577 8.74961 0.747269C9.13248 1.09921 9.13248 1.68867 8.74961 2.04061L3.7828 6.60606L15.5714 6.60606C16.0438 6.60606 16.5 6.96746 16.5 7.5C16.5 8.03254 16.0438 8.39394 15.5714 8.39394Z"
          fill="white" stroke="white" />
      </svg>
    </div>
  </div>
  <div #testimonials class="d-fl jc-fs cards-container centered-container">
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/ee.svg" alt="">
      <p class="card-title">Estonia</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/fi.svg" alt="">
      <p class="card-title">Finland</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/no.svg" alt="">
      <p class="card-title">Norway</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/at.svg" alt="">
      <p class="card-title">Austria</p>
    </div>
    <!--  -->
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/hu.svg" alt="">
      <p class="card-title">Hungary</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/pl.svg" alt="">
      <p class="card-title">Poland</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/ir.svg" alt="">
      <p class="card-title">Ireland</p>
    </div>
    <div (swipeleft)="moveRight()" (swiperight)="moveLeft()" [ngStyle]="{'left.px': position}" class="card-country">
      <img src="../../../../assets/images/svg/flags/gb.svg" alt="">
      <p class="card-title">New Zealand</p>
    </div>
  </div>
</section>

<section class="team-container" data-scroll-section>
  <div class="text-container title-subtitle-container centered-container">
    <p class="title">Our team</p>
    <p class="subtitle">All of the Spinwise brands are top performers in their markets, and we ensure our state of the
      art product along with our exceptional team keep it that way. Spinwise consists of brilliant individuals who are
      driven to become leaders in the entertainment industry and are succeeding at it.</p>
  </div>
  <!-- <app-team-photos [cardArr]="teamPhotos"></app-team-photos> -->
</section>

<app-get-in-touch title="Let's talk" subtitle="get in touch">
</app-get-in-touch>

<section data-scroll-section>
  <app-contact-us></app-contact-us>
</section>
<section data-scroll-section>
  <app-arrow-banner></app-arrow-banner>

</section>
