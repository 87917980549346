<div class="payments-card">
  <!-- <svg-icon src="{{icon}}" [stretch]="true" svgClass="arrow"></svg-icon> -->
  <svg *ngIf="icon == 'amp'" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.3 15.301L37.72 9.72104C37.08 9.08104 36 9.52104 36 10.421V14.001H8C6.9 14.001 6 14.901 6 16.001C6 17.101 6.9 18.001 8 18.001H36V21.581C36 22.481 37.08 22.921 37.7 22.281L43.28 16.701C43.68 16.321 43.68 15.681 43.3 15.301Z" fill="#FF8041" />
    <path d="M4.69661 32.701L10.2766 38.281C10.9166 38.921 11.9966 38.481 11.9966 37.581V34.001H39.9966C41.0966 34.001 41.9966 33.101 41.9966 32.001C41.9966 30.901 41.0966 30.001 39.9966 30.001H11.9966V26.421C11.9966 25.521 10.9166 25.081 10.2966 25.721L4.71661 31.301C4.31661 31.681 4.31661 32.321 4.69661 32.701Z" fill="#101820" />
  </svg>
  <svg *ngIf="icon == 'crypto'" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24ZM24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0Z" fill="#FF8041" />
    <path d="M20.1923 15.6667H26.8846C28.725 15.6667 30.2308 17.1667 30.2308 19C30.2308 20.8333 28.725 22.3333 26.8846 22.3333H20.1923V15.6667ZM28.5577 32.3333H20.1923V25.6667H28.5577C30.3981 25.6667 31.9038 27.1667 31.9038 29C31.9038 30.8333 30.3981 32.3333 28.5577 32.3333ZM28.5577 10.6667C28.5577 9.75 27.8048 9 26.8846 9C25.9644 9 25.2115 9.75 25.2115 10.6667V12.3333H21.8654V10.6667C21.8654 9.75 21.1125 9 20.1923 9C19.2721 9 18.5192 9.75 18.5192 10.6667V12.3333H15.1731C14.2529 12.3333 13.5 13.0833 13.5 14C13.5 14.9167 14.2529 15.6667 15.1731 15.6667H16.8462V32.3333H15.1731C14.2529 32.3333 13.5 33.0833 13.5 34C13.5 34.9167 14.2529 35.6667 15.1731 35.6667H18.5192V37.3333C18.5192 38.25 19.2721 39 20.1923 39C21.1125 39 21.8654 38.25 21.8654 37.3333V35.6667H25.2115V37.3333C25.2115 38.25 25.9644 39 26.8846 39C27.8048 39 28.5577 38.25 28.5577 37.3333V35.6667C32.2552 35.6667 35.25 32.6833 35.25 29C35.25 26.5833 33.945 24.45 32.0042 23.2833C32.9913 22.1333 33.5769 20.6333 33.5769 19C33.5769 15.9 31.4521 13.2833 28.5577 12.55V10.6667Z" fill="#101820" />
  </svg>
  <svg *ngIf="icon == 'fast'" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.033 19.873L21.9569 28H14.9959C13.2362 28 14.3358 26.5 14.3768 26.4395C16.8104 22.1367 20.4061 15.8359 25.1561 7.50586L26.0154 6H28.0154L26.033 19.873Z" fill="#FF8041" />
    <path d="M21.957 28H21.9961L19.9961 42H21.9961C21.9961 42 25.9355 35.0996 33.8359 21.3203C34.0371 20.8906 34.0547 20.5781 33.9375 20.3652C33.7969 20.1113 33.4688 20 33.0352 20H26.0156L26.0332 19.873L21.957 28Z" fill="#101820" />
  </svg>
  <svg *ngIf="icon == 'limits'" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36 42C37.1 42 38 41.1 38 40L38 30L34 30L34 40C34 41.1 34.9 42 36 42ZM12 42C13.1 42 14 41.1 14 40L14 22L10 22L10 40C10 41.1 10.9 42 12 42ZM40 22L38 22L38 8C38 6.9 37.1 6 36 6C34.9 6 34 6.9 34 8L34 22L32 22C30.9 22 30 22.9 30 24C30 25.1 30.9 26 32 26L40 26C41.1 26 42 25.1 42 24C42 22.9 41.1 22 40 22ZM18 16C18 14.9 17.1 14 16 14L14 14L14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8L10 14L8 14C6.9 14 6 14.9 6 16C6 17.1 6.9 18 8 18L16 18C17.1 18 18 17.1 18 16Z" fill="#FF8041" />
    <path d="M20 34L22 34L22 40C22 41.1 22.9 42 24 42C25.1 42 26 41.1 26 40L26 34L28 34C29.1 34 30 33.1 30 32C30 30.9 29.1 30 28 30L20 30C18.9 30 18 30.9 18 32C18 33.1 18.9 34 20 34ZM24 6C22.9 6 22 6.9 22 8L22 26L26 26L26 8C26 6.9 25.1 6 24 6Z" fill="#101820" />
  </svg>
  <svg *ngIf="icon == 'payment-small'" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8H40C42.22 8 44 9.78 44 12V36C44 38.22 42.22 40 40 40H8C5.78 40 4 38.22 4 36L4.02 12C4.02 9.78 5.78 8 8 8ZM10 36H38C39.1 36 40 35.1 40 34V14C40 12.9 39.1 12 38 12H10C8.9 12 8 12.9 8 14V34C8 35.1 8.9 36 10 36Z" fill="#FF8041" />
    <rect x="8" y="16" width="32" height="8" fill="#101820" />
  </svg>

  <div class="payments-text-container">
    <p class="card-title">{{title}}</p>
    <p class="card-subtitle">{{subtitle}}</p>
  </div>
</div>
