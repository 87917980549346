<section class="container-dark d-fl fd-c ai-c centered-container gutter-container section__start commissions__container" data-scroll data-scroll-section>
  <app-page-title position="center" title="Make life more fun" subtitle="Join us" description="We believe that anyone should be able to have more fun in their life. Not only our clients but our team as well. That is why we always ask ourselves - will this make life more fun?">
  </app-page-title>
  <div class="careers__open-positions d-fl fd-c jc-c ai-c">
    <div>
      <p><b>{{openPositions}}</b> open positions</p>
    </div>
    <a routerLink="/careers/hiring" class="btn btn--primary">View all</a>
  </div>
</section>
<section class="container-dark d-fl fd-c centered-container gutter-container commissions__container" data-scroll data-scroll-section data-scroll-call="sticky-title" data-scroll-repeat="true" data-scroll-offset="50%, 0%">
  <div class="careers_about-us__container">
    <div class="about-us__block">
      <div class="about-us__description">
        <h1>Who we are</h1>
        <p>Founded by a group of industry veterans and a stellar team, Spinwise lives and breathes gambling. Our
          brand mission statement is “It’s time to take fun more seriously” because the entertainment industry
          is serious business.</p>
        <a href="/some-link">Get to know us</a>
      </div>
      <div class="about-us__image about-us__image--who">
      </div>
    </div>
    <div class="about-us__block">
      <div class="about-us__description">
        <h1>What we offer</h1>
        <p>We believe that you need to have fun to make fun. That is why Spinwise is dedicated to nurturing an
          environment where fun-loving professionals can thrive. .</p>
        <a routerLink="/careers">See the perks</a>
      </div>
      <div class="about-us__image about-us__image--offer">
      </div>
    </div>
    <div class="about-us__block">
      <div class="about-us__description">
        <h1>Who we hire</h1>
        <p>If you can squeeze the fun out of it - you're hired! Proactive people dedicated to making life more
          fun one smile at a time are the perfect match for our passion-driven team.</p>
        <a routerLink="/careers/hiring">See all positions</a>
      </div>
      <div class="about-us__image about-us__image--hire">
        <!-- <img src="../../../../assets/images/careers/who-we-hire.svg"/> -->
      </div>
    </div>
  </div>
  <!-- <app-arrow-banner-small
     actionText="View all"
     actionUrl="/careers/hiring"
     [amount]="openPositions"
     bannerTitle="Open positions"
    ></app-arrow-banner-small> -->
</section>
<section class="container-dark d-fl fd-c ai-c centered-container gutter-container commissions__container" data-scroll data-scroll-section>

  <div class="careers__container">
    <h1>Our offices</h1>
    <p>Heart of the Baltics is the perfect hub for a fast-growing team like ours. Whether you are craving a Latvian
      flare or a Nordic twist to your lifestyle, Spinwise offices are waiting for you.</p>
  </div>

  <div class="careers__offices">
    <a routerLink="/careers/riga/hiring" class="careers__office">
      <img src="../../../../assets/images/riga.png" />
      <div>
        <h3>Riga, Latvia</h3>
        <div class="link--arrow">
          <p>{{getOpenPositionText(rigaPositions)}}</p>
          <img src="../../../../assets/images/icons/svg/arrow-right.svg" />
        </div>
      </div>
    </a>
    <a routerLink="/careers/tallinn/hiring" class="careers__office">
      <img src="../../../../assets/images/tallinn.png" />
      <div>
        <h3>Tallinn, Estonia</h3>
        <div class="link--arrow">
          <p>{{getOpenPositionText(tallinnPositions)}}</p>
          <img src="../../../../assets/images/icons/svg/arrow-right.svg" />
        </div>
      </div>
    </a>
    <a routerLink="/careers/limassol/hiring" class="careers__office">
      <img src="../../../../assets/images/limassol.png" />
      <div>
        <h3>Limassol, Cyprus</h3>
        <div class="link--arrow">
          <p>{{getOpenPositionText(limassolPositions)}}</p>
          <img src="../../../../assets/images/icons/svg/arrow-right.svg" />
        </div>
      </div>
    </a>
  </div>
</section>
<section data-scroll data-scroll-section>
  <app-auto-carousel [images]="autoScrollImages"></app-auto-carousel>
</section>
<section class="container-dark centered-container gutter-container section__container" data-scroll data-scroll-section data-scroll-call="sticky-title-close" data-scroll-repeat="true" data-scroll-offset="0%, 0%">
  <app-positions [limit]=4 title="Join the fun" subtitle="Open positions"></app-positions>
</section>
<section class="container-wide no-match__container" data-scroll data-scroll-section id="contact-us-section" data-offset="100">
  <div class="container-wide d-fl jc-c ai-c" class="no-much-container">
    <div class="d-fl fd-c jc-c ai-c get-in-touch__container">
      <h1 class="text--regular t-c-7 fade-in" data-scroll data-scroll-speed="-0.5">No match?</h1>
      <h2 class="text--regular t-c-4 fade-in" data-scroll data-scroll-speed="0">Get in touch</h2>
    </div>
  </div>
  <!-- <app-no-match></app-no-match> -->
  <div style="width: 100%;">
    <app-contact-us contactOf="hr"></app-contact-us>
  </div>
</section>
<section data-scroll-section>
</section>
<app-scroll-banner [scrollInputs]="scrollInputs" dataCall="sticky-title" dataCallClose="sticky-title-close">
</app-scroll-banner>