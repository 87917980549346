import { Component, OnInit } from '@angular/core';
import { ScrollManagerService } from 'src/app/services/scroll-manager.service';
import { EPaymentSystemCardSize, IPaymentSystemCard } from './payment-system-card/payment-system-card.component';
import { IPaymentsCard } from './payments-card/payments-card.component';
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  constructor(public scrollManagerService: ScrollManagerService) { }
  paymentsCards: Array<IPaymentsCard> = [
    { src: `amp`, title: 'AMPs', subtitle: 'Our vast variety of payment methods will suffice for any player or affiliate' },
    { src: 'payment-small', title: 'Cards', subtitle: 'Visa, Mastercard and Maestro are all welcome on all our platforms' },
    { src: 'fast', title: 'Fast transactions', subtitle: 'All transactions are processed in the blink of an eye' },
    { src: 'crypto', title: 'Cryptocurrency', subtitle: 'No crypto enthusiast will be left behind on all of our platforms' },
    { src: 'limits', title: 'Fair limits', subtitle: 'The transaction sum limits are all fair and square' },
  ];
  paymentsSystemCards: Array<IPaymentSystemCard> = [
    { src: '/assets/images/payments/n26.svg', isMoved: false, size: EPaymentSystemCardSize.small },
    { src: '/assets/images/payments/skrill.svg', isMoved: true, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/revolut.svg', isMoved: false, size: EPaymentSystemCardSize.small },
    { src: '/assets/images/payments/volt.svg', isMoved: false, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/swed.svg', isMoved: true, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/trustly.svg', isMoved: false, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/neosurf.svg', isMoved: false, size: EPaymentSystemCardSize.small },
    { src: '/assets/images/payments/neteller.svg', isMoved: true, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/btc.svg', isMoved: false, size: EPaymentSystemCardSize.small },
    { src: '/assets/images/payments/isignthis.svg', isMoved: false, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/piqsofort.svg', isMoved: true, size: EPaymentSystemCardSize.default },
    { src: '/assets/images/payments/cashtocode.svg', isMoved: false, size: EPaymentSystemCardSize.default },
    { src: '', isMoved: false, size: EPaymentSystemCardSize.default  },
    { src: '/assets/images/payments/trustly.svg', isMoved: true, size: EPaymentSystemCardSize.default  },
    { src: '', isMoved: false, size: EPaymentSystemCardSize.default  },
  ];
  paymentsSystemCardsMobile: Array<IPaymentSystemCard> = [
    { src: '/assets/images/payments/n26.svg', isMoved: true },
    { src: '/assets/images/payments/skrill.svg', isMoved: false },
    { src: '/assets/images/payments/revolut.svg', isMoved: true },
    { src: '/assets/images/payments/swed.svg', isMoved: false },
    { src: '/assets/images/payments/volt.svg', isMoved: true },
    { src: '/assets/images/payments/trustly.svg', isMoved: true },
    { src: '/assets/images/payments/neosurf.svg', isMoved: false },
    { src: '/assets/images/payments/neteller.svg', isMoved: true },
    { src: '/assets/images/payments/btc.svg', isMoved: false },
    { src: '/assets/images/payments/piqsofort.svg', isMoved: true },
    { src: '/assets/images/payments/isignthis.svg', isMoved: true },
    { src: '/assets/images/payments/cashtocode.svg', isMoved: false },
    { src: '/assets/images/payments/isignthis.svg', isMoved: true },
    { src: '/assets/images/payments/trustly.svg', isMoved: false },
    { src: '/assets/images/payments/swed.svg', isMoved: true },
  ];
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.scrollManagerService.updateScroll(false);
  }

}
