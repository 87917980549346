import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clear-cut-commissions',
  templateUrl: './clear-cut-commissions.component.html',
  styleUrls: ['./clear-cut-commissions.component.scss']
})
export class ClearCutCommissionsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
