<section class="centered-container gutter-container d-fl fd-c jc-sb ai-c features__container">
    <div class="features__main" id="features" data-scroll >
        <div class="features__main--wrapper d-fl" >
            <div class="features--right d-fl fd-c jc-fe ai-fs" data-scroll data-scroll-offset="-50%, 0">
                <p data-scroll data-scroll-call="step-1" data-scroll-offset="50%, 50%"><b>No</b> negative carryover</p>
                <p data-scroll data-scroll-call="step-2" data-scroll-offset="50%, 50%">Paid for <b>NGR</b></p>
                <p data-scroll data-scroll-call="step-3" data-scroll-offset="50%, 50%"><b>No</b> admin or other fees</p>
                <p data-scroll data-scroll-call="step-4" data-scroll-offset="50%, 50%">Get what you earn, <b>always on time</b></p>
            </div>
        </div>
    </div>
</section>
