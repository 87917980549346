<section class="container-dark d-fl fd-c ai-c centered-container gutter-container section__start section__container"  data-scroll data-scroll-section>
  <app-page-title title="Join the fun" subtitle="Positions" position="center" description="We believe that anyone should be able to have more fun in life. Not only our clients, but our team as well. This is why we always ask ourselves - is this going to make life more fun?"></app-page-title>
</section>
<section class="container-dark d-fl fd-c ai-c centered-container gutter-container section__container position-selection__container" data-scroll
  data-scroll-section>

  <app-dropdown-menu (onSelectItemEvent)="onSelectUpdate($event)" countPositions="{{this.countPositions}}" selectedCity="{{locationMap[location]}}"></app-dropdown-menu>
  <app-positions [viewAll]=false [location]="locationMap[location]"></app-positions>

</section>
<!-- <section class="container-wide no-match__container" data-scroll data-scroll-section id="contact-us-section" data-offset="110">
  <app-no-match></app-no-match>
</section> -->
<section class="container-wide no-match__container" data-scroll data-scroll-section id="contact-us-section"
  data-offset="100">
  <div class="container-wide d-fl jc-c ai-c" class="no-much-container">
    <div class="d-fl fd-c jc-c ai-c get-in-touch__container">
      <h1 class="text--regular t-c-7 fade-in" data-scroll data-scroll-speed="-0.5">No match?</h1>
      <h2 class="text--regular t-c-4 fade-in" data-scroll data-scroll-speed="0">Get in touch</h2>
    </div>
  </div>
  <!-- <app-no-match></app-no-match> -->
  <div style="width: 100%;">
    <app-contact-us contactOf="hr"></app-contact-us>
  </div>
</section>

